import React, { useContext, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled
} from './styles'
import { CgClose } from 'react-icons/cg'
import { auth, firestore } from '../../../firebase'
import moment from 'moment'
import Dropdown from '../../Dropdowns/Dropdown'
import firebase from 'firebase'
import { firstToUpper } from '../../../lib/helpers'

interface ISubcategorias {
  uid: string
  name: string
  category: {
    uid: string
    name: string
  }
}
interface Timestamp {
  seconds: number
}

interface IProfissionais {
  uid: string
  name: string
  email: string
  phone: string
  active: boolean
  instagram: string
  facebook: string
  address: string
  isProfessional: boolean
  subcategory?: ISubcategorias
  isHighLight?: boolean
  description?: string
  profileImage?: string
  workImages?: string[]
  created_at: Timestamp
  updated_at?: Timestamp
}

interface INewUser {
  name: string
  phone: string
  instagram: string
  facebook: string
  isProfessional: boolean
  description?: string
  address: string
  email: string
  profileImage?: string | firebase.firestore.FieldValue
  isHighLight?: boolean
  workImages?: string[]
  created_at?: Date
  updated_at?: Date
  active: boolean
  subcategory?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
}

interface CreateProfissionalModalProps {
  close: () => void
  profissionalChange?: IProfissionais
  getProfissionais: () => Promise<void>
}

const CreateProfissionalModal: React.FC<CreateProfissionalModalProps> = ({
  close,
  profissionalChange,
  getProfissionais
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [name, setName] = useState<string>(
    profissionalChange ? profissionalChange.name : ''
  )

  const [email, setEmail] = useState<string>(
    profissionalChange ? profissionalChange.email : ''
  )

  const [phone, setPhone] = useState<string>(
    profissionalChange ? profissionalChange.phone : ''
  )
  const [active, setActive] = useState<boolean>(
    profissionalChange ? profissionalChange.active : false
  )

  const [facebook, setFacebook] = useState<string>(
    profissionalChange?.facebook || ''
  )

  const [changePassword, setChangePassword] = useState<boolean>(false)

  const [instagram, setInstagram] = useState<string>(
    profissionalChange?.instagram || ''
  )

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const newUser: INewUser = {
        name: firstToUpper(name),
        email,
        isProfessional: false,
        phone,
        facebook,
        instagram,
        active,
        address: ''
      }

      if (profissionalChange) {
        newUser.updated_at = new Date()
      } else {
        newUser.created_at = new Date()
      }
      let hasUser = false
      if (profissionalChange?.uid) {
        const checkUsers = await firestore
          .collection('UsersCollection')
          .where('isProfessional', '==', false)
          .where(
            firebase.firestore.FieldPath.documentId(),
            '!=',
            profissionalChange?.uid
          )
          .where('email', '==', email)
          .get()
        if (checkUsers.docs.length > 0) hasUser = true
      }
      if (hasUser) {
        showAlert(
          'error',
          'Ocorreu um erro!',
          'E-mail já está sendo utilizado!'
        )
      } else {
        await firestore
          .collection('UsersCollection')
          .doc(profissionalChange?.uid)
          .set(newUser, { merge: true })
        if (changePassword) {
          await auth.sendPasswordResetEmail(email)
        }

        getProfissionais()

        showAlert(
          'success',
          'Sucesso!',
          `Usuário ${profissionalChange ? 'atualizado' : 'criado'} com sucesso!`
        )
      }
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível ativar o usuário!'
      )
    }
    setIsLoading(false)
    close()
  }

  let created_at = ''
  if (profissionalChange) {
    created_at = moment(profissionalChange?.created_at.seconds * 1000).format(
      'DD/MM/YYYY HH:mm:ss'
    )
  }

  let updated_at = ''
  if (profissionalChange && profissionalChange.updated_at) {
    updated_at = moment(profissionalChange?.updated_at.seconds * 1000).format(
      'DD/MM/YYYY HH:mm:ss'
    )
  }

  return (
    <Container>
      <Header>
        <Title>{profissionalChange ? 'Editar Usuário' : 'Criar Usuário'}</Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Nome:</InputLabel>
          <InputStyled value={name} onChange={e => setName(e.target.value)} />

          <InputLabel>E-mail:</InputLabel>
          <InputStyled value={email} onChange={e => setEmail(e.target.value)} />
          <Dropdown
            id={8}
            isSearchable={false}
            label="Enviar e-mail para resetar senha?"
            disabled={isLoading}
            changeFunction={(v: string) => setChangePassword(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: changePassword },
              { label: 'Não', value: 'false', selected: !changePassword }
            ]}
          />
          <InputLabel>Telefone:</InputLabel>
          <InputStyled value={phone} onChange={e => setPhone(e.target.value)} />
          <InputLabel>Facebook:</InputLabel>
          <InputStyled
            value={facebook}
            onChange={e => setFacebook(e.target.value)}
          />

          <InputLabel>Instagram:</InputLabel>
          <InputStyled
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
          />

          {profissionalChange && (
            <>
              <InputLabel>Criado em:</InputLabel>
              <InputStyled value={created_at} readOnly={true} />

              <InputLabel>Atualizado em:</InputLabel>
              <InputStyled value={updated_at} readOnly={true} />
            </>
          )}
          <Dropdown
            id={6}
            isSearchable={false}
            label="Ativo?"
            disabled={isLoading}
            changeFunction={(v: string) => setActive(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: active },
              { label: 'Não', value: 'false', selected: !active }
            ]}
          />
          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar Usuário' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default CreateProfissionalModal
