import React, { useState } from 'react'

import logo from '../../assets/images/Logo.svg'
import { useAuth } from '../../contexts/AuthContext'

import {
  Container,
  FormWrapper,
  LogoContainer,
  InputsContainer,
  InputLabel,
  InputStyled,
  ButtonFilled,
  FooterForm,
  KeepLogged
} from './styles'

const FormularioLogin: React.FC = () => {
  const { login, recoverPassword } = useAuth()

  const [recovery, setRecovery] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [resetEmail, setResetEmail] = useState('')
  const [isSending, setIsSending] = useState(false)

  const clearAll = () => {
    setEmail('')
    setPassword('')
    setResetEmail('')
    setRecovery(false)
  }

  const handleLogin = async () => {
    setIsSending(true)
    await login(email, password)
    setIsSending(false)
  }

  const handleResetPassword = async () => {
    setIsSending(true)
    await recoverPassword(resetEmail)
    setIsSending(false)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (recovery) {
      handleResetPassword()
    } else {
      handleLogin()
    }
  }

  return (
    <>
      <Container>
        <FormWrapper>
          <form onSubmit={handleSubmit}>
            <LogoContainer>
              <img src={logo} alt="Spot Player" />
            </LogoContainer>
            {!recovery ? (
              <>
                <InputsContainer>
                  <InputLabel>E-mail</InputLabel>
                  <InputStyled
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <InputLabel>Senha</InputLabel>
                  <InputStyled
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <ButtonFilled disabled={isSending}>
                    {isSending ? 'Enviando...' : 'Login'}
                  </ButtonFilled>
                </InputsContainer>
                <FooterForm>
                  <KeepLogged></KeepLogged>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setRecovery(true)
                      setResetEmail(email)
                    }}
                  >
                    Esqueceu a senha?
                  </a>
                </FooterForm>
              </>
            ) : (
              <>
                <InputsContainer>
                  <InputLabel>E-mail</InputLabel>
                  <InputStyled
                    value={resetEmail}
                    onChange={e => setResetEmail(e.target.value)}
                  />
                </InputsContainer>
                <ButtonFilled disabled={isSending}>
                  {isSending ? 'Enviando...' : 'RECUPERAR SENHA'}
                </ButtonFilled>
                <FooterForm>
                  <KeepLogged></KeepLogged>
                  <a style={{ cursor: 'pointer' }} onClick={clearAll}>
                    voltar para o login
                  </a>
                </FooterForm>
              </>
            )}
          </form>
        </FormWrapper>
      </Container>
    </>
  )
}

export default FormularioLogin
