import React, { useEffect, useRef, useState } from 'react'

import {
  Container,
  InputLabel,
  InputStyled,
  ArrowSelect,
  InputsContainer,
  DropdownContainer,
  DropdownItem,
  SearchContainer,
  SearchBar
} from './styles'

import { FiChevronDown } from 'react-icons/fi'
import { BiSearch } from 'react-icons/bi'

interface DropdownProps {
  id: number
  label?: string
  changeFunction: (value: string) => void
  items: {
    value: string
    label: string
    selected?: boolean
  }[]
  isSearchable?: boolean
  isTitle?: boolean
  disabled?: boolean
}

const Dropdown: React.FC<DropdownProps> = props => {
  const [value, setValue] = useState(0)
  const [active, setActive] = useState(false)
  const [items, setItems] = useState(props.items)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  const handleSearch = (e: string) => {
    const normalizer = (text: string) => {
      return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    }
    const newItems = props.items.filter(value => {
      return normalizer(value.label).includes(normalizer(e))
    })
    setItems(newItems)
  }

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  useEffect(() => {
    const index = props.items.findIndex(i => i.selected)
    if (index >= 0) {
      setValue(index)
      setActive(false)
    } else {
      setValue(0)
    }
  }, [props.items])

  return (
    <Container>
      <InputLabel isTitle={props.isTitle}>
        {props.label}
        <ArrowSelect
          isTitle={props.isTitle}
          onClick={() => {
            if (props.isTitle) {
              setActive(true)
            }
          }}
        >
          <FiChevronDown size={20} />
        </ArrowSelect>
      </InputLabel>
      <InputsContainer ref={ref}>
        <InputStyled
          isTitle={props.isTitle}
          placeholder="Selecione uma opção"
          defaultValue=""
          value={props.items.length > 0 ? props.items[value]?.label : ''}
          disabled={props.disabled}
          //  Ao clicar, exibe o modal
          onFocus={() => {
            setActive(true)
          }}
        />
        <DropdownContainer active={active}>
          {props.isSearchable && (
            <SearchContainer
              autoComplete="off"
              style={items.length > 0 ? { paddingBottom: 0 } : {}}
            >
              <BiSearch />
              <SearchBar
                autoComplete="false"
                onChange={e => handleSearch(e.currentTarget.value)}
              />
            </SearchContainer>
          )}
          {items.map((item, i) => (
            <DropdownItem
              key={i}
              onClick={() => {
                setValue(i)
                setActive(false)
                props.changeFunction && props.changeFunction(item.value)
              }}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownContainer>
      </InputsContainer>
    </Container>
  )
}

export default Dropdown
