import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 250px;
  min-width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  -webkit-box-shadow: 5px 0px 15px 0px rgba(138, 149, 158, 0.1);
  -moz-box-shadow: 5px 0px 15px 0px rgba(138, 149, 158, 0.1);
  box-shadow: 5px 0px 15px 0px rgba(138, 149, 158, 0.1);

  @media (max-width: 1025px) {
    height: 65px;
    width: 100%;
    max-width: 100vw;
    min-width: inherit;
    //overflow: hidden;
    padding-bottom: 0;
    position: relative;
  }
`

export const ContentWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 1025px) {
    height: 100%;
    flex-direction: row;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    background: white;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  margin-bottom: 30px;

  @media (max-width: 1025px) {
    padding: 0;
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
`

export const ProfileImage = styled.div`
  display: flex;
  width: 75px;
  height: 75px;
  border-radius: 75px;
  margin-top: 30px;
  margin-bottom: 20px;

  & > img {
    object-fit: cover;
    width: 75px;
    height: 75px;
    border-radius: 75px;

    -webkit-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
    -moz-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
    box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);

    @media (max-width: 1025px) {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 1025px) {
    width: 40px;
    height: 40px;
    margin: 0px;
  }
`

export const ProfileName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: var(--dark-gray);

  @media (max-width: 1025px) {
    font-size: 20px;
    margin-left: 15px;
  }
`

export const ProfileDesc = styled.div`
  font-size: 15px;
  font-weight: 300;
  color: var(--dark-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: 1025px) {
    display: none;
  }
`

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`

export const ActiveIndicator = styled.div`
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: var(--primary);
  left: 0;
`

interface MenuItemProps {
  active?: boolean
  isExit?: boolean
}

export const MenuItem = styled.div.attrs(props => props)<MenuItemProps>`
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  color: ${props => (props.active ? 'var(--primary)' : 'var(--dark-gray)')};
  cursor: pointer;
  transition: 0.2s ease;

  & > span {
    font-size: 15px;
  }
  &:hover {
    background: ${props => (props.active ? 'var(--primary)' : '#F7F8FC')};
    color: ${props => (props.active ? 'white' : 'var(--dark-gray)')};
  }
  ${ActiveIndicator} {
    display: ${props => (props.active ? 'flex' : 'none')};
  }
`

export const MenuIcon = styled.div`
  margin-left: 30px;
  margin-right: 15px;
  display: flex;
  align-items: center;
`

interface HamburguerMenuProps {
  isOpened?: boolean
}

export const HamburguerMenu = styled.button.attrs(
  props => props
)<HamburguerMenuProps>`
  display: flex;
  align-items: center;
  color: var(--medium-gray);
  position: relative;

  :focus {
    outline: none;
  }
  & > svg {
    position: absolute;
    right: 0;
  }

  svg:nth-child(1) {
    transition: 0.3s ease-out;
    opacity: ${props => (props.isOpened ? 0 : 1)};
  }

  svg:nth-child(2) {
    transition: 0.3s ease-in;
    opacity: ${props => (props.isOpened ? 1 : 0)};
  }

  @media (min-width: 1025px) {
    display: none;
  }
`

interface MenuContentProps {
  isOpened?: boolean
}

export const MenuContent = styled.div<MenuContentProps>`
  display: flex;
  flex: 1;
  flex-direction: column;

  justify-content: space-between;

  @media (max-width: 1025px) {
    z-index: 1;
    position: absolute;
    top: 65px;
    background: white;
    width: 100vw;
    height: calc(65vh - 65px);
    padding: 25px 0;
    -webkit-box-shadow: 5px 0px 15px 0px rgba(138, 149, 158, 0.1);
    -moz-box-shadow: 5px 0px 15px 0px rgba(138, 149, 158, 0.1);
    box-shadow: 5px 0px 15px 0px rgba(138, 149, 158, 0.1);
    border-top: 1px solid #f1f1f1;
    transform: translate(
      0,
      ${props => (props.isOpened ? 0 : 'calc(-65vh - 70px)')}
    );
    transition: 0.5s ease;
  }
`

export const Divider = styled.div`
  display: flex;
  height: 1px;
  width: 90%;
  margin: 10px auto;
  background-color: var(--light-gray);
`

export const ScrollContent = styled.div`
  max-height: 250px;
  overflow: auto;
`
