import React, { useContext, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled,
  ImagesUploadContainer
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore, uploadImage } from '../../../firebase'
import SinglePictureUpload from '../../SinglePictureUpload'
import firebase from 'firebase'
import { firstToUpper } from '../../../lib/helpers'

interface ICategorias {
  uid: string
  name: string
  default_picture?: string
  created_at: Date
  updated_at: Date
}

interface ICategoriasData {
  name: string
  default_picture?: string | firebase.firestore.FieldValue
  created_at?: Date
  updated_at?: Date
}

interface CreateCategoryModalProps {
  close: () => void
  categoryChange?: ICategorias
  getCategories: () => Promise<void>
}

const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({
  close,
  categoryChange,
  getCategories
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [categoryName, setCategoryName] = useState<string>(
    categoryChange ? categoryChange.name : ''
  )

  const [defaultPicture, setDefaultPicture] = useState<string>(
    categoryChange?.default_picture || ''
  )

  const handleSubmit = async () => {
    setIsLoading(true)

    if (!categoryName.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o nome da categoria!'
      )
    }

    const categoryData: ICategoriasData = {
      name: firstToUpper(categoryName)
    }

    if (defaultPicture !== categoryChange?.default_picture) {
      categoryData.default_picture = await uploadImage(
        'Categories',
        defaultPicture
      )
    }

    if (!defaultPicture) {
      categoryData.default_picture = firebase.firestore.FieldValue.delete()
    }

    if (categoryChange) {
      categoryData.updated_at = new Date()
    } else {
      categoryData.created_at = new Date()
    }

    await firestore
      .collection('CategoriesCollection')
      .doc(categoryChange?.uid)
      .set(categoryData, { merge: true })

    getCategories()

    try {
      showAlert(
        'success',
        'Sucesso!',
        `Categoria ${
          categoryChange?.uid ? 'atualizada' : 'criada'
        } com sucesso!`
      )
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        `Não foi possível ${
          categoryChange?.uid ? 'atualizar' : 'criar'
        } a categoria!`
      )
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>{categoryChange ? 'Editar Categoria' : 'Criar Categoria'}</Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Nome:</InputLabel>
          <InputStyled
            value={categoryName}
            onChange={e => setCategoryName(e.target.value)}
          />

          <InputLabel>Imagem:</InputLabel>

          <ImagesUploadContainer>
            <SinglePictureUpload
              image={defaultPicture}
              setImage={setDefaultPicture}
              disabled={isLoading}
            />
          </ImagesUploadContainer>

          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar categoria' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default CreateCategoryModal
