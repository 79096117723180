import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import UsersContent from '../../ListagemContent/UsersContent'
import Title from '../../Title'
import { Container } from './styles'

const Users: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="usuarios" />
      <Content>
        <Title text="Usuários" />
        <UsersContent />
      </Content>
    </Container>
  )
}

export default Users
