import React from 'react'

import {
  Container,
  ContainerWrapper,
  ContentWrapper,
  Spacement,
  TextContainer,
  Title
} from './styles'
import imageMacbook from '../../assets/images/Mockup.png'

interface BannerPropos {
  title: string
  description: string
}

const Banner: React.FC<BannerPropos> = ({ title, description }) => {
  return (
    <Container>
      <ContainerWrapper>
        <ContentWrapper>
          <Spacement />
          <img src={imageMacbook} alt="MV Prec" />
          <TextContainer>
            <Title>{title}</Title>
            <span>{description}</span>
          </TextContainer>
        </ContentWrapper>
      </ContainerWrapper>
    </Container>
  )
}

export default Banner
