/* eslint-disable multiline-ternary */
import React, { useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol
} from './styles'

import { Modal } from '@material-ui/core'
import ContactsModal from '../../Modals/ContactsModal'
import TermsModal from '../../Modals/TermsModal'

const ConfigsContent: React.FC = () => {
  const [showModalContact, setShowModalModalContact] = useState(false)
  const [showModalTerms, setShowModalModalTerms] = useState(false)

  return (
    <>
      <Modal
        open={showModalContact}
        onClose={() => setShowModalModalContact(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContactsModal close={() => setShowModalModalContact(false)} />
      </Modal>

      <Modal
        open={showModalTerms}
        onClose={() => setShowModalModalTerms(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <TermsModal close={() => setShowModalModalTerms(false)} />
      </Modal>
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Item</th>
          </TableDescription>
          <TableContent>
            <TableItem
              onClick={() => {
                setShowModalModalContact(true)
              }}
            >
              <ItemCol>
                <span>Contatos</span>
              </ItemCol>
            </TableItem>
            <TableItem
              onClick={() => {
                setShowModalModalTerms(true)
              }}
            >
              <ItemCol>
                <span>Termos</span>
              </ItemCol>
            </TableItem>
          </TableContent>
        </Table>
      </TableContainer>
    </>
  )
}

export default ConfigsContent
