import React from 'react'

import { Container, Wrapper, NavigationButton, ItemButton, Etc } from './styles'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

interface PaginationProps {
  numberOfItems: number
  fn: any
  actualPage: number
}

const Pagination: React.FC<PaginationProps> = props => {
  return (
    <Container>
      {props.actualPage === 0 && (
        <NavigationButton onClick={() => props.fn(props.actualPage - 1)}>
          <FiChevronLeft size={24} />
        </NavigationButton>
      )}
      <Wrapper>
        {Array.from({ length: props.numberOfItems }).map((_, i) => {
          if (
            i === 0 ||
            i + 1 === props.actualPage ||
            i === props.actualPage ||
            i - 1 === props.actualPage ||
            i + 1 === props.numberOfItems
          ) {
            return (
              <ItemButton
                active={i + 1 === props.actualPage}
                key={i + 1}
                onClick={() => props.fn(i + 1)}
              >
                {i + 1}
              </ItemButton>
            )
          } else if (i >= 3 && i + 2 === props.numberOfItems) {
            return <Etc>...</Etc>
          } else return null
        })}
      </Wrapper>
      {props.numberOfItems !== props.actualPage && (
        <NavigationButton onClick={() => props.fn(props.actualPage + 1)}>
          <FiChevronRight size={24} />
        </NavigationButton>
      )}
    </Container>
  )
}

export default Pagination
