const moneyFormatter = (value: string): string => {
  const v = Math.round(parseFloat(value) * 100) / 100
  return v.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

const phoneFormatter = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{2})(\d{4})(\d{5})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  const match2 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)
  if (match2) {
    return '(' + match2[1] + ') ' + match2[2] + '-' + match2[3]
  }
  return phone
}
const checkArrayIsSame = (array1: string[], array2: string[]): boolean => {
  const is_same =
    array1.length === array2.length &&
    array1.every(function (element, index) {
      return element === array2[index]
    })

  return is_same
}

const firstToUpper = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

export { moneyFormatter, phoneFormatter, checkArrayIsSame, firstToUpper }
