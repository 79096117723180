import styled from 'styled-components'

export const Container = styled.div`
  width: 40%;
  height: 100vh;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const Spacement = styled.div`
  width: 100%;
  height: 68px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  & > img {
    width: 120px;
    object-fit: cover;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: var(--black);
  height: 30px;
  display: flex;
  align-items: center;
`

interface InputProps {
  isPassword?: boolean
}

export const InputStyled = styled.input.attrs(props => props)<InputProps>`
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  border: 1.5px solid var(--light-gray);
  color: var(--dark-gray);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 7px;
  transition: 0.2s ease;

  &:focus {
    outline-color: var(--primary);
  }
`

export const ButtonFilled = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--primary);
  color: white;
  font-size: 12px;
  font-weight: 800;
  transition: 0.2s ease;
  margin-bottom: 20px;
  margin-top: 5px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: var(--primary-hover);
  }
  &:focus {
    outline-color: var(--primary);
  }
`

export const FooterForm = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > a {
    font-size: 12px;
    font-weight: 700;
    color: var(--primary);
    margin-left: 8px;
    text-decoration: none;
    transition: 0.2s ease;

    &:hover {
      filter: brightness(0.8);
    }
  }
`

export const KeepLogged = styled.div`
  display: flex;
  align-items: center;

  & > span {
    font-size: 12px;
    font-weight: 700;
    color: var(--medium-gray);
    margin-left: 8px;
  }
`

export const CheckBoxContainer = styled.div`
  display: flex;
`

export const HiddenCheckbox = styled.input.attrs(props => props)`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const CheckedSquare = styled.div`
  width: 8px;
  height: 8px;
  background-color: var(--primary);
  border-radius: 2px;
  transition: 0.2s ease;
`

interface CheckBoxProps {
  checked: boolean
}

export const StyledCheckbox = styled.div<CheckBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: white;
  border: 2px solid
    ${props => (props.checked ? 'var(--primary)' : 'var(--light-gray)')};
  border-radius: 5px;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    background: #f1f1f1;
  }

  ${HiddenCheckbox}:focus + & {
    //box-shadow: 0 0 0 3px pink;
  }
  ${CheckedSquare} {
    opacity: ${props => (props.checked ? 1 : 0)};
  }
`
export const TokenUpload = styled.div`
  width: 100%;
  max-width: 300px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--medium-gray);

  & > span {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > button {
      font-size: 12px;
      font-weight: 700;
      color: var(--primary);
      text-decoration: none;
      transition: 0.2s ease;
      cursor: pointer;
      display: flex;

      &:hover {
        filter: brightness(0.8);
      }
      &:focus {
        outline: none;
      }
    }
  }
`

export const FormDivisor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  & > span {
    margin: 0 10px;
    font-size: 14px;
    color: var(--medium-gray);
  }
`

export const LineDivisor = styled.div`
  height: 1px;
  display: flex;
  flex: 1;
  background: #f1f1f1;
`

export const SocialButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

interface SocialButtonProps {
  color: string
  color2: string
}

export const SocialButton = styled.button.attrs(
  props => props
)<SocialButtonProps>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => props.color};
  transition: 0.2s ease;
  color: white;
  cursor: pointer;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);

  &:hover {
    background: ${props => props.color2};
  }
  &:focus {
    outline-color: var(--primary);
  }
  :nth-child(1) {
    margin-right: 10px;
  }
`

export const ErrorMessage = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  color: var(--red-indicator);
`
