/* eslint-disable multiline-ternary */
import React from 'react'
import Button from '../Button'

import { Container, Wrapper, FilterMenu } from './styles'

interface ListagemHeaderProps {
  buttonText: string
  fn: () => void
}

const ListagemHeader: React.FC<ListagemHeaderProps> = ({ buttonText, fn }) => {
  return (
    <Container>
      <Wrapper></Wrapper>
      <FilterMenu>
        <Button size="small" text={buttonText} onClick={fn} />
      </FilterMenu>
    </Container>
  )
}

export default ListagemHeader
