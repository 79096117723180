import React, { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled,
  ImagesUploadContainer
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore, uploadImage } from '../../../firebase'
import Dropdown from '../../Dropdowns/Dropdown'
import firebase from 'firebase'
import SinglePictureUpload from '../../SinglePictureUpload'
import { firstToUpper } from '../../../lib/helpers'

interface Item {
  value: string
  label: string
  selected?: boolean
}

interface ICategorias {
  uid: string
  name: string
  created_at: Date
  updated_at: Date
}

interface ISubCategorias {
  uid: string
  name: string
  category: ICategorias
  default_picture?: string
  created_at: Date
  updated_at: Date
}

interface ISubCategoriasData {
  name: string
  category: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  default_picture?: string | firebase.firestore.FieldValue
  created_at?: Date
  updated_at?: Date
}

interface CreateSubCategoryModalProps {
  close: () => void
  subcategoryChange?: ISubCategorias
  getSubCategories: () => Promise<void>
}

const CreateSubCategoryModal: React.FC<CreateSubCategoryModalProps> = ({
  close,
  subcategoryChange,
  getSubCategories
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [categoriesItems, setCategoriesItems] = useState<Item[]>([])

  const [category, setCategory] = useState<string>(
    subcategoryChange ? subcategoryChange.category.uid : ''
  )

  const [subcategoryName, setSubcategoryName] = useState<string>(
    subcategoryChange ? subcategoryChange.name : ''
  )

  const [defaultPicture, setDefaultPicture] = useState<string>(
    subcategoryChange?.default_picture || ''
  )

  const getCategories = async () => {
    const ctgs = await firestore
      .collection('CategoriesCollection')
      .orderBy('name')
      .get()

    const data = ctgs.docs.map(doc => {
      const data = doc.data()
      return {
        label: data.name,
        value: doc.id,
        selected: doc.id === category
      }
    })

    setCategoriesItems(data)

    if (!category) {
      setCategory(data[0]?.value)
    }
  }

  useEffect(() => {
    try {
      getCategories()
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível buscar as categorias!'
      )
    }
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)

    if (!subcategoryName.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o nome da subcategoria!'
      )
    }

    if (!category.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, selecione uma categoria!'
      )
    }

    const categoryDocRef = firestore
      .collection('CategoriesCollection')
      .doc(category)

    const subcategoryData: ISubCategoriasData = {
      name: firstToUpper(subcategoryName),
      category: categoryDocRef
    }

    if (defaultPicture !== subcategoryChange?.default_picture) {
      subcategoryData.default_picture = await uploadImage(
        'Subcategories',
        defaultPicture
      )
    }

    if (!defaultPicture) {
      subcategoryData.default_picture = firebase.firestore.FieldValue.delete()
    }

    if (subcategoryChange) {
      subcategoryData.updated_at = new Date()
    } else {
      subcategoryData.created_at = new Date()
    }

    await firestore
      .collection('SubCategoriesCollection')
      .doc(subcategoryChange?.uid)
      .set(subcategoryData, { merge: true })

    getSubCategories()

    try {
      showAlert(
        'success',
        'Sucesso!',
        `Subcategoria ${
          subcategoryChange?.uid ? 'atualizada' : 'criada'
        } com sucesso!`
      )
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        `Não foi possível ${
          subcategoryChange?.uid ? 'atualizar' : 'criar'
        } a subcategoria!`
      )
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>
          {subcategoryChange ? 'Editar Subcategoria' : 'Criar Subcategoria'}
        </Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Nome:</InputLabel>
          <InputStyled
            value={subcategoryName}
            onChange={e => setSubcategoryName(e.target.value)}
          />

          <Dropdown
            id={4}
            isSearchable={true}
            label="Categoria"
            disabled={!categoriesItems.length}
            changeFunction={(v: string) => setCategory(v)}
            items={categoriesItems}
          />
          <InputLabel>Imagem:</InputLabel>
          <ImagesUploadContainer>
            <SinglePictureUpload
              image={defaultPicture}
              setImage={setDefaultPicture}
              disabled={isLoading}
            />
          </ImagesUploadContainer>
          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar subcategoria' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default CreateSubCategoryModal
