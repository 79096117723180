/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol,
  PuffContent
} from './styles'

import Pagination from '../../Pagination'
import { firestore } from '../../../firebase'
import { Modal } from '@material-ui/core'
import CreateImovelModal from '../../Modals/CreateImovelModal'
import ListagemHeader from '../../ListagemHeader'
import firebase from 'firebase'
import { moneyFormatter } from '../../../lib/helpers'
import Loading from '../../Loading'

interface IImovel {
  uid: string
  district: string
  street: string
  owner_name: string
  active: true
  footage: number
  value: number
  description: string
  created_at: Date
  updated_at: Date
  owner_whatsapp?: string
  owner_facebook?: string
  owner_instagram?: string
  owner_email?: string
  default_picture?: string
  pictures?: string[]
}

const ImoveisContent: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [imoveis, setImoveis] = useState<IImovel[]>([])

  const itemByPage = 10
  const [page, setPage] = useState(1)
  const changePage = (n: number) => setPage(n)

  const [lastDoc, setLastDoc] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >()

  const [totalPages, setTotalPages] = useState(1)

  const getImoveis = async () => {
    setLoading(true)

    const totalPages = await firestore
      .collection('PropertiesCollection')
      .get()
      .then(r => Math.ceil(r.docs.length / itemByPage))

    const imvs = await firestore
      .collection('PropertiesCollection')
      .orderBy('district')
      .startAfter(page === 1 ? 0 : lastDoc)
      .limit(itemByPage)
      .get()

    const data = imvs.docs.map(doc => ({
      uid: doc.id,
      ...doc.data()
    }))

    setLastDoc(imvs.docs[imvs.docs.length - 1])
    setImoveis(data as IImovel[])
    setTotalPages(totalPages)
    setLoading(false)
  }
  useEffect(() => {
    getImoveis()
  }, [page])

  const [imovelChange, setImovelChange] = useState<IImovel>()

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreateImovelModal
          close={() => setShowModal(false)}
          imovelChange={imovelChange}
          getImoveis={getImoveis}
        />
      </Modal>
      <ListagemHeader
        buttonText="Adicionar Imóvel"
        fn={() => {
          setShowModal(true)
          setImovelChange(undefined)
        }}
      />
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Bairro</th>
            <th>Proprietário</th>
            <th>Valor</th>
            <th>Metragem</th>
          </TableDescription>
          <TableContent>
            {loading ? (
              <PuffContent>
                <Loading />
              </PuffContent>
            ) : (
              imoveis.map((imovel, i) => (
                <TableItem
                  key={i}
                  onClick={() => {
                    setShowModal(true)
                    setImovelChange(imovel)
                  }}
                >
                  <ItemCol>
                    <span>{imovel.district}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{imovel.owner_name}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{moneyFormatter(imovel.value.toString())}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{imovel.footage}</span>
                  </ItemCol>
                </TableItem>
              ))
            )}
          </TableContent>
        </Table>
      </TableContainer>
      <Pagination
        numberOfItems={totalPages}
        fn={changePage}
        actualPage={page}
      />
    </>
  )
}

export default ImoveisContent
