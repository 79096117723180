/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol,
  PuffContent
} from './styles'

import Pagination from '../../Pagination'
import { firestore } from '../../../firebase'
import { Modal } from '@material-ui/core'
import CreateAutomovelModal from '../../Modals/CreateAutomovelModal'
import ListagemHeader from '../../ListagemHeader'
import firebase from 'firebase'
import { moneyFormatter } from '../../../lib/helpers'
import Loading from '../../Loading'

interface IAutomovel {
  uid: string
  model: string
  mileage: number
  owner_name: string
  active: boolean
  year: string
  description: string
  value: number
  created_at: Date
  updated_at: Date
  owner_whatsapp?: string
  owner_facebook?: string
  owner_instagram?: string
  owner_email?: string
  default_picture?: string
  pictures?: string[]
}

const AutomoveisContent: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [automoveis, setAutomoveis] = useState<IAutomovel[]>([])

  const itemByPage = 10
  const [page, setPage] = useState(1)
  const changePage = (n: number) => setPage(n)

  const [lastDoc, setLastDoc] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >()

  const [totalPages, setTotalPages] = useState(1)

  const getAutomoveis = async () => {
    setLoading(true)

    const totalPages = await firestore
      .collection('VehiclesCollection')
      .get()
      .then(r => Math.ceil(r.docs.length / itemByPage))

    const atvs = await firestore
      .collection('VehiclesCollection')
      .orderBy('model')
      .startAfter(page === 1 ? 0 : lastDoc)
      .limit(itemByPage)
      .get()

    const data = atvs.docs.map(doc => ({
      uid: doc.id,
      ...doc.data()
    }))

    setLastDoc(atvs.docs[atvs.docs.length - 1])
    setAutomoveis(data as IAutomovel[])
    setTotalPages(totalPages)
    setLoading(false)
  }

  useEffect(() => {
    getAutomoveis()
  }, [page])

  const [automovelChange, setAutomovelChange] = useState<IAutomovel>()

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreateAutomovelModal
          close={() => setShowModal(false)}
          automovelChange={automovelChange}
          getAutomoveis={getAutomoveis}
        />
      </Modal>
      <ListagemHeader
        buttonText="Adicionar Automóvel"
        fn={() => {
          setShowModal(true)
          setAutomovelChange(undefined)
        }}
      />
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Modelo</th>
            <th>Quilometragem</th>
            <th>Valor</th>
            <th>Ano</th>
            <th>Proprietário</th>
          </TableDescription>
          <TableContent>
            {loading ? (
              <PuffContent>
                <Loading />
              </PuffContent>
            ) : (
              automoveis.map((auto, i) => (
                <TableItem
                  key={i}
                  onClick={() => {
                    setShowModal(true)
                    setAutomovelChange(auto)
                  }}
                >
                  <ItemCol>
                    <span>{auto.model}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>
                      {auto.mileage
                        .toString()
                        .replace(/(.)(?=(\d{3})+$)/g, '$1.')}
                    </span>
                  </ItemCol>
                  <ItemCol>
                    <span>{moneyFormatter(auto.value.toString())}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{auto.year}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{auto.owner_name}</span>
                  </ItemCol>
                </TableItem>
              ))
            )}
          </TableContent>
        </Table>
      </TableContainer>
      <Pagination
        numberOfItems={totalPages}
        fn={changePage}
        actualPage={page}
      />
    </>
  )
}

export default AutomoveisContent
