import React, { useContext, useState, createContext, useEffect } from 'react'
import AlertBallon from '../components/AlertBallon'

interface IFilter {
  id: number
  name: string
  nature?: string | null
  origin?: string | null
  status?: string | null
  situation?: string | null
  min_sale_off?: string | null
  max_sale_off?: string | null
  min_value?: number | null
  max_value?: number | null
  min_due_date?: string | null
  max_due_date?: string | null
  debtor_entity?: string | null
  users_id?: number
  keywords: string[]
}

interface ContextProps {
  showAlert: (
    type: string,
    title: string,
    content: string,
    percentage?: number
  ) => void
  // Modal criar filtro
  showModalCreateFilter: boolean
  handleToggleCreateFilter: (id?: number) => void
  // Modal salvar filtro
  showModalSaveFilter: boolean
  handleToggleSaveFilter: () => void
  // Modal coleta
  showModalColeta: boolean
  handleToggleColeta: (id?: number) => void
  collectId: number | undefined
  // Modal visualizar
  showModalVisualizar: boolean
  handleToggleVisualizar: (id?: number) => void
  // Modal raspagem
  showModalRaspagem: boolean
  handleToggleRaspagem: () => void
  // PrecatorioModal
  precatoryId: number | undefined
  // Active filter
  changeActiveFilter: (f: number | undefined) => void
  activeFilter: number | undefined
  updateFilter: boolean
  editFilter: IFilter | undefined
  updateDebtorEntitiesFilter: (db: string[]) => void
  debtorEntitiesFilter: string[]
  updateSituationsFilter: (situations: string[]) => void
  situationsFilter: string[]
}

export const GeneralContext = createContext<ContextProps>({} as ContextProps)

export const GeneralProvider: React.FC = ({ children }: any) => {
  const [precatoryId, setPrecatoryId] = useState<number>()
  const [collectId, setCollectId] = useState<number>()

  const [debtorEntitiesFilter, setDebtorEntitiesFilter] = useState<string[]>([])
  const [situationsFilter, setSituationsFilter] = useState<string[]>([])

  const [filterId, setFilterId] = useState<number | undefined>()

  const [editFilter, setEditFilter] = useState<IFilter>()

  const [alertBaloonData, setAlertbaloonData] = useState({
    opened: false,
    type: 'error',
    title: 'Ocorreu um erro!',
    content: 'Tente novamente mais tarde.',
    progressPercentage: 0
  })

  const closeAlert = () => {
    setAlertbaloonData({
      ...alertBaloonData,
      opened: false
    })
  }

  const showAlert = (
    type: string,
    title: string,
    content: string,
    percentage?: number
  ) => {
    setAlertbaloonData({
      opened: true,
      type,
      title,
      content,
      progressPercentage: percentage || 0
    })
    setTimeout(closeAlert, 3000)
  }

  const getEditFilter = async () => {
    setEditFilter(undefined)
  }

  useEffect(() => {
    if (filterId) {
      getEditFilter()
    } else {
      setEditFilter(undefined)
    }
  }, [filterId])

  const updateDebtorEntitiesFilter = (db: string[]) => {
    setDebtorEntitiesFilter(db)
  }

  const updateSituationsFilter = (situations: string[]) => {
    setSituationsFilter(situations)
  }

  // Modal criar filtro
  const [showModalCreateFilter, setShowModalCreateFilter] = useState(false)

  const handleToggleCreateFilter = (id?: number) => {
    setShowModalCreateFilter(!showModalCreateFilter)
    setFilterId(id)
  }

  // Modal salvar filtro
  const [showModalSaveFilter, setShowModalSaveFilter] = useState(false)

  const handleToggleSaveFilter = () => {
    setShowModalSaveFilter(!showModalSaveFilter)
  }

  // Modal coleta
  const [showModalColeta, setShowModalColeta] = useState(false)

  const handleToggleColeta = (id?: number) => {
    setCollectId(!showModalColeta ? id : undefined)
    setShowModalColeta(!showModalColeta)
  }

  // Modal visualizar
  const [showModalVisualizar, setShowModalVisualizar] = useState(false)

  const handleToggleVisualizar = (id?: number) => {
    setPrecatoryId(!showModalVisualizar ? id : undefined)
    setShowModalVisualizar(!showModalVisualizar)
  }

  // Modal raspagem
  const [showModalRaspagem, setShowModalRaspagem] = useState(false)

  const handleToggleRaspagem = () => {
    setShowModalRaspagem(!showModalRaspagem)
  }

  // Active Filter
  const [activeFilter, setActiveFilter] = useState<number | undefined>()
  const [updateFilter, setUpdateFilter] = useState<boolean>(false)

  const changeActiveFilter = (f: number | undefined) => {
    setActiveFilter(f)
    setUpdateFilter(!updateFilter)
  }

  // Geral
  useEffect(() => {
    const closeAllModals = (e: any) => {
      if (e.keyCode === 27) {
        setShowModalCreateFilter(false)
      }
    }
    window.addEventListener('keydown', closeAllModals)
    return () => window.removeEventListener('keydown', closeAllModals)
  }, [])

  return (
    <GeneralContext.Provider
      value={{
        showAlert,
        showModalCreateFilter,
        handleToggleCreateFilter,
        showModalSaveFilter,
        handleToggleSaveFilter,
        showModalColeta,
        handleToggleColeta,
        collectId,
        showModalVisualizar,
        handleToggleVisualizar,
        precatoryId,
        showModalRaspagem,
        handleToggleRaspagem,
        activeFilter,
        updateFilter,
        changeActiveFilter,
        editFilter,
        updateDebtorEntitiesFilter,
        debtorEntitiesFilter,
        updateSituationsFilter,
        situationsFilter
      }}
    >
      <AlertBallon {...alertBaloonData} closeFunction={closeAlert} />
      {children}
    </GeneralContext.Provider>
  )
}

export const useGeneral = (): ContextProps => useContext(GeneralContext)
