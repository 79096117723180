import React, { useContext, useState, useEffect } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  ButtonFilled,
  TextAreaStyled
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore } from '../../../firebase'

interface TermsModalProps {
  close: () => void
}

const TermsModal: React.FC<TermsModalProps> = ({ close }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [uid, setUid] = useState<string>()
  const [terms, setTerms] = useState<string>('')

  const getTerms = async () => {
    const ctgs = await firestore.collection('TermsCollection').get()

    const data = ctgs.docs.map(doc => {
      const data = doc.data()
      return {
        uid: doc.id,
        terms: data.terms
      }
    })

    if (data.length) {
      const { uid, terms } = data[0]
      setUid(uid)
      setTerms(terms)
    }
  }

  useEffect(() => {
    try {
      getTerms()
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível buscar os termos!'
      )
    }
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)
    await firestore
      .collection('TermsCollection')
      .doc(uid)
      .set({ terms }, { merge: true })

    try {
      showAlert('success', 'Sucesso!', 'Termos atualizados com sucesso!')
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível atualizar os termos!'
      )
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>Editar Termos</Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Termos:</InputLabel>
          <TextAreaStyled
            value={terms}
            onChange={e => setTerms(e.target.value)}
          />

          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar termos' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default TermsModal
