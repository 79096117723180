import React from 'react'

import { Container, Text } from './styles'

interface TitleProps {
  text: string
}

const Title: React.FC<TitleProps> = props => {
  return (
    <Container>
      <Text>{props.text}</Text>
    </Container>
  )
}

export default Title
