import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import AutomoveisContent from '../../ListagemContent/AutomoveisContent'
import Title from '../../Title'
import { Container } from './styles'

const Automoveis: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="automoveis" />
      <Content>
        <Title text="Automóveis" />
        <AutomoveisContent />
      </Content>
    </Container>
  )
}

export default Automoveis
