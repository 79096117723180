import React, { useContext, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  ButtonFilled,
  ImagesUploadContainer,
  InputStyled
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore, uploadImage } from '../../../firebase'
import SinglePictureUpload from '../../SinglePictureUpload'
interface IBannersData {
  link: string
  default_picture: string
  created_at: Date
}

interface BannersModalProps {
  close: () => void
  getBanners: () => Promise<void>
}

const BannersModal: React.FC<BannersModalProps> = ({ close, getBanners }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [defaultPicture, setDefaultPicture] = useState<string>('')
  const [link, setLink] = useState('')

  const handleSubmit = async () => {
    setIsLoading(true)

    if (!defaultPicture.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o banner!'
      )
    }

    const bannersData: IBannersData = {
      link,
      created_at: new Date(),
      default_picture: await uploadImage('Banners', defaultPicture)
    }

    await firestore
      .collection('BannersCollection')
      .doc()
      .set(bannersData, { merge: true })

    getBanners()

    try {
      showAlert('success', 'Sucesso!', 'Banner criado com sucesso!')
    } catch (e) {
      console.log(e)
      showAlert('error', 'Ocorreu um erro!', 'Não foi possível criar o banner!')
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>Criar Banner</Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Link:</InputLabel>
          <InputStyled value={link} onChange={e => setLink(e.target.value)} />
          <InputLabel>Imagem:</InputLabel>
          <ImagesUploadContainer>
            <SinglePictureUpload
              image={defaultPicture}
              setImage={setDefaultPicture}
              disabled={isLoading}
            />
          </ImagesUploadContainer>

          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar Banner' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default BannersModal
