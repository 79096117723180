/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol,
  PuffContent
} from './styles'

import Pagination from '../../Pagination'
import { firestore } from '../../../firebase'
import { Modal } from '@material-ui/core'
import CreateProfissionalModal from '../../Modals/CreateProfissionalModal'
import firebase from 'firebase'
import { phoneFormatter } from '../../../lib/helpers'
import ListagemHeader from '../../ListagemHeader'
import Loading from '../../Loading'

interface ISubcategorias {
  uid: string
  name: string
  category: {
    uid: string
    name: string
  }
}

interface Timestamp {
  seconds: number
}

interface IProfissionais {
  uid: string
  name: string
  email: string
  phone: string
  active: boolean
  address: string
  facebook: string
  instagram: string
  isProfessional: boolean
  isHighLight: boolean
  subcategory: ISubcategorias
  description: string
  profileImage?: string
  workImages: string[]
  created_at: Timestamp
  updated_at?: Timestamp
}

const ProfissionaisContent: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [profissionais, setProfissionais] = useState<IProfissionais[]>([])

  const itemByPage = 10
  const [page, setPage] = useState(1)
  const changePage = (n: number) => setPage(n)

  const [lastDoc, setLastDoc] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >()

  const [totalPages, setTotalPages] = useState(1)

  const getProfissionais = async () => {
    setLoading(true)

    const totalPages = await firestore
      .collection('UsersCollection')
      .where('isProfessional', '==', true)
      .get()
      .then(r => Math.ceil(r.docs.length / itemByPage))

    let users: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>

    if (page === 1) {
      users = await firestore
        .collection('UsersCollection')
        .where('isProfessional', '==', true)
        .orderBy('active', 'asc')
        .orderBy('name', 'asc')
        .limit(itemByPage)
        .get()
    } else {
      users = await firestore
        .collection('UsersCollection')
        .where('isProfessional', '==', true)
        .orderBy('active', 'asc')
        .orderBy('name', 'asc')
        .startAfter(lastDoc)
        .limit(itemByPage)
        .get()
    }

    const data: IProfissionais[] = []
    for (let i = 0; i < users.docs.length; i++) {
      const doc = users.docs[i].data()
      const subcategory = doc.subcategory?.get
        ? await doc.subcategory?.get()
        : null
      const subcategoryData = subcategory?.data()
      const category = await subcategoryData?.category?.get()
      const categoryData = category?.data()

      data.push({
        uid: users.docs[i].id,
        name: doc.name,
        email: doc.email,
        phone: doc.phone,
        active: doc.active,
        isProfessional: doc.isProfessional,
        description: doc.description,
        profileImage: doc.profileImage,
        workImages: doc.workImages,
        isHighLight: doc.isHighLight,
        created_at: doc.created_at,
        updated_at: doc.updated_at,
        address: doc.address,
        facebook: doc.facebook,
        instagram: doc.instagram,
        subcategory: {
          uid: subcategory?.id,
          name: subcategoryData?.name,
          category: {
            uid: category?.id,
            name: categoryData?.name
          }
        }
      })
    }

    setLastDoc(users.docs[users.docs.length - 1])
    setProfissionais(data as IProfissionais[])
    setTotalPages(totalPages)
    setLoading(false)
  }
  useEffect(() => {
    getProfissionais()
  }, [page])

  const [profissionalChange, setProfissionalChange] = useState<IProfissionais>()

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreateProfissionalModal
          close={() => setShowModal(false)}
          profissionalChange={profissionalChange}
          getProfissionais={getProfissionais}
        />
      </Modal>
      <ListagemHeader
        buttonText="Adicionar Profissional"
        fn={() => {
          setShowModal(true)
          setProfissionalChange(undefined)
        }}
      />
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Nome</th>
            <th>E-mail</th>
            <th>WhatsApp</th>
            <th>Ativo?</th>
          </TableDescription>
          <TableContent>
            {loading ? (
              <PuffContent>
                <Loading />
              </PuffContent>
            ) : (
              profissionais.map((profissional, i) => (
                <TableItem
                  key={i}
                  onClick={() => {
                    setProfissionalChange(profissional)
                    setShowModal(true)
                  }}
                >
                  <ItemCol>
                    <span>{profissional.name}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{profissional.email}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{phoneFormatter(profissional.phone)}</span>
                  </ItemCol>

                  <ItemCol>
                    <span>{profissional.active ? 'Sim' : 'Não'}</span>
                  </ItemCol>
                </TableItem>
              ))
            )}
          </TableContent>
        </Table>
      </TableContainer>
      <Pagination
        numberOfItems={totalPages}
        fn={changePage}
        actualPage={page}
      />
    </>
  )
}

export default ProfissionaisContent
