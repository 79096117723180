import styled from 'styled-components'
import bauhaus from '../../assets/images/BauHaus.png'

export const Container = styled.div`
  width: 60%;
  height: 100vh;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const ContainerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${bauhaus});
  background-color: var(--secondary);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  /* background-blend-mode: overlay; */

  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  width: 90%;

  & > img {
    width: 100%;
  }
`

export const Spacement = styled.div`
  width: 100%;
  height: 95px;
`

export const TextContainer = styled.div`
  width: 100%;
  max-width: 330px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  color: white;

  & > span {
    font-size: 14px;
  }
`

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 900;
`
