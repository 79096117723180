/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol,
  PuffContent
} from './styles'

import Pagination from '../../Pagination'
import { firestore } from '../../../firebase'
import { Modal } from '@material-ui/core'
import CreateSubCategoryModal from '../../Modals/CreateSubCategoryModal'
import ListagemHeader from '../../ListagemHeader'
import firebase from 'firebase'
import Loading from '../../Loading'

interface ICategorias {
  uid: string
  name: string
  default_picture?: string
  created_at: Date
  updated_at: Date
}

interface ISubCategorias {
  uid: string
  name: string
  default_picture?: string
  category: ICategorias
  created_at: Date
  updated_at: Date
}

const SubCategoriasContent: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [subcategorias, setSucategories] = useState<ISubCategorias[]>([])

  const itemByPage = 10
  const [page, setPage] = useState(1)
  const changePage = (n: number) => setPage(n)

  const [lastDoc, setLastDoc] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >()

  const [totalPages, setTotalPages] = useState(1)

  const getSubCategories = async () => {
    setLoading(true)

    const totalPages = await firestore
      .collection('SubCategoriesCollection')
      .get()
      .then(r => Math.ceil(r.docs.length / itemByPage))

    const ctgs = await firestore
      .collection('SubCategoriesCollection')
      .orderBy('name')
      .startAfter(page === 1 ? 0 : lastDoc)
      .limit(itemByPage)
      .get()

    const data: ISubCategorias[] = []

    for (let i = 0; i < ctgs.docs.length; i++) {
      const doc = ctgs.docs[i].data()
      const category = await doc.category.get()
      const categoryData = category.data()

      data.push({
        uid: ctgs.docs[i].id,
        name: doc.name,
        default_picture: doc.default_picture,
        created_at: doc.created_at,
        updated_at: doc.updated_at,
        category: {
          uid: category.id,
          ...categoryData
        }
      })
    }

    setLastDoc(ctgs.docs[ctgs.docs.length - 1])
    setSucategories(data as ISubCategorias[])
    setTotalPages(totalPages)
    setLoading(false)
  }
  useEffect(() => {
    getSubCategories()
  }, [page])

  const [subcategoryChange, setSubcategoryChange] = useState<ISubCategorias>()
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreateSubCategoryModal
          close={() => setShowModal(false)}
          subcategoryChange={subcategoryChange}
          getSubCategories={getSubCategories}
        />
      </Modal>
      <ListagemHeader
        buttonText="Adicionar Subcategoria"
        fn={() => {
          setShowModal(true)
          setSubcategoryChange(undefined)
        }}
      />
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Nome</th>
            <th>Categoria</th>
          </TableDescription>
          <TableContent>
            {loading ? (
              <PuffContent>
                <Loading />
              </PuffContent>
            ) : (
              subcategorias.map((subcategoria, i) => (
                <TableItem
                  key={i}
                  onClick={() => {
                    setShowModal(true)
                    setSubcategoryChange(subcategoria)
                  }}
                >
                  <ItemCol>
                    <span>{subcategoria.name}</span>
                  </ItemCol>
                  <ItemCol>
                    <span>{subcategoria.category.name}</span>
                  </ItemCol>
                </TableItem>
              ))
            )}
          </TableContent>
        </Table>
      </TableContainer>
      <Pagination
        numberOfItems={totalPages}
        fn={changePage}
        actualPage={page}
      />
    </>
  )
}

export default SubCategoriasContent
