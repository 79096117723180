import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import SubCategoriasContent from '../../ListagemContent/SubCategoriasContent'
import Title from '../../Title'
import { Container } from './styles'

const Subcategorias: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="subcategorias" />
      <Content>
        <Title text="Subcategorias" />
        <SubCategoriasContent />
      </Content>
    </Container>
  )
}

export default Subcategorias
