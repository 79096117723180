import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 335px;
  background-color: white;
  position: absolute;
  border-radius: 5px;
  bottom: 50px;
  overflow-y: auto;

  -webkit-box-shadow: 5px 5px 10px 0px rgba(138, 149, 158, 0.15);
  -moz-box-shadow: 5px 5px 10px 0px rgba(138, 149, 158, 0.15);
  box-shadow: 5px 5px 10px 0px rgba(138, 149, 158, 0.15);

  @media (max-width: 600px) {
    min-width: inherit;
    width: 95%;
  }
`

export const Header = styled.div`
  display: flex;
  min-height: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
`

export const Title = styled.div`
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  color: var(--primary);
`

export const CloseIcon = styled.button.attrs(props => props)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  transition: 0.3s ease;
  cursor: pointer;
  color: var(--dark-gray);

  :hover {
    background: #f1f1f1;
  }
  :focus {
    outline: none;
  }
`

export const BodyWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1;
`

export const Body = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: fit-content;

  @media (max-width: 600px) {
    flex-direction: column;
    padding-top: 15px;
  }
`

export const InputLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-gray);
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
`
export const LabelDescription = styled.div`
  font-size: 11px;
  color: var(--dark-gray);
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 14px;
`

export const InputStyled = styled.input.attrs(props => props)`
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  border: 1.5px solid var(--medium-gray);
  color: var(--dark-gray);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: 0.2s ease;

  &:focus {
    outline-color: var(--primary);
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--medium-gray);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--medium-gray);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--medium-gray);
  }
`

export const TextAreaStyled = styled.textarea.attrs(props => props)`
  width: 100%;
  height: 100px;
  padding: 5px;
  border-radius: 4px;
  border: 1.5px solid var(--medium-gray);
  color: var(--dark-gray);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: 0.2s ease;
`
export const SelectStyled = styled.select.attrs(props => props)`
  width: 100%;
  height: 40px;
  padding: 0 15px;
  padding-right: 44px;
  border-radius: 4px;
  border: 1.5px solid var(--medium-gray);
  color: var(--dark-gray);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: 0.2s ease;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  & > option {
  }

  &:focus {
    outline-color: var(--primary);
  }
`

export const ArrowSelect = styled.div`
  display: flex;
  right: 15px;
  top: 40px;
  position: absolute;
  width: 20px;
  justify-content: center;
  z-index: 1;
`

export const ColForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${InputStyled}:nth-child(1) {
    margin-bottom: 0;
  }
`

export const ButtonFilled = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--primary);
  color: white;
  font-size: 12px;
  font-weight: 800;
  transition: 0.2s ease;
  margin-top: 18px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background: var(--primary-hover);
  }
  &:focus {
    outline-color: var(--primary);
  }
  > svg {
    height: 70%;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  position: relative;

  ${InputStyled}:nth-child(2) {
    margin-left: 10px;
  }
`

interface DropdownProps {
  active?: boolean
}

export const DropdownContainer = styled.div<DropdownProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% - 15px);
  left: 0;
  right: 0;
  transition: 0.2s ease;

  background-color: white;
  z-index: 1;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);

  transform: ${props => (props.active ? '' : 'translateY(-10%)')};
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
`

export const DropdownItem = styled.div`
  padding: 15px;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--light-gray);
  }
`
export const ImagesUploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  margin-bottom: 20px;
`
