import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsPlusCircle } from 'react-icons/bs'
import {
  Container,
  Img,
  Label,
  InputImage,
  RemoveButton,
  Scroll,
  ImgContainer
} from './styles'
interface MultiplePictureUploadProps {
  images: string[]
  disabled: boolean
  setImages?: Dispatch<SetStateAction<string[]>>
}

const MultiplePictureUpload: React.FC<MultiplePictureUploadProps> = ({
  images,
  disabled,
  setImages
}) => {
  const [imagesFile, setImagesFile] = useState<FileList>()

  useEffect(() => {
    if (imagesFile && setImages) {
      for (let i = 0; i < imagesFile.length; i++) {
        const file = imagesFile[i]
        const reader = new FileReader()
        reader.onloadend = () => {
          if (reader.result) {
            const base64String = reader.result.toString()
            setImages(prevState => [...prevState, base64String])
          }
        }
        reader.readAsDataURL(file)
      }
    }
  }, [imagesFile])

  return (
    <Container>
      {!images.length ? (
        <>
          <Label htmlFor="multipleuploadfile">
            {setImages && <BsPlusCircle size={22} color="#B3B3B3" />}
          </Label>
          <InputImage
            id="multipleuploadfile"
            type="file"
            accept="image/*"
            multiple
            disabled={disabled}
            onChange={e => {
              if (e?.target?.files?.length) {
                setImagesFile(e.target.files)
              }
            }}
          />
        </>
      ) : (
        <>
          <Scroll>
            {images.map((image, i) => (
              <>
                <ImgContainer key={i}>
                  <Img src={image} />
                  {setImages && (
                    <RemoveButton
                      onClick={() => {
                        const newImages = images.filter((_, idx) => i !== idx)
                        setImages(newImages)

                        if (!newImages.length) {
                          setImagesFile(undefined)
                        }
                      }}
                    >
                      <AiOutlineCloseCircle size={18} color="white" />
                    </RemoveButton>
                  )}
                </ImgContainer>
              </>
            ))}
          </Scroll>
        </>
      )}
    </Container>
  )
}

export default MultiplePictureUpload
