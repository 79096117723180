import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'

interface AdminRouteProps extends RouteProps {
  component: any
  redirectTo: string
}

const AdminRoute = ({
  component: RouteComponent,
  redirectTo,
  ...rest
}: AdminRouteProps): React.ReactElement => {
  const { authenticated, isAuthLoading } = useAuth()

  if (isAuthLoading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    )
  }
  return (
    <Route
      {...rest}
      render={routeProps =>
        authenticated ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{ pathname: redirectTo, state: { prevPath: rest.path } }}
          />
        )
      }
    />
  )
}
export default AdminRoute
