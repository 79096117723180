import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import ProfissionaisContent from '../../ListagemContent/ProfissionaisContent'
import Title from '../../Title'
import { Container } from './styles'

const Home: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="profissionais" />
      <Content>
        <Title text="Profissionais" />
        <ProfissionaisContent />
      </Content>
    </Container>
  )
}

export default Home
