import firebase from 'firebase'
import { v4 as uuidv4 } from 'uuid'

const firebaseConfig = {
  apiKey: 'AIzaSyBIzSUXUgxy2O0I2cOTFWuY8sTxH0WRW-s',
  authDomain: 'visancebr-ef208.firebaseapp.com',
  databaseURL: 'https://visancebr-ef208.firebaseio.com',
  projectId: 'visancebr-ef208'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

const firestore = firebase.firestore()
const storage = firebase.app().storage('gs://visancebr-ef208.appspot.com')

const uploadImage = async (folder: string, image: string): Promise<string> => {
  const imageBlob = await fetch(image).then(r => r.blob())
  const format = image.substring('data:image/'.length, image.indexOf(';base64'))
  const uuidString = uuidv4()
  const storageRef = storage.ref()
  const upload = await storageRef
    .child(`${folder}/${uuidString}.${format}`)
    .put(imageBlob)
  const url: string = await upload.ref.getDownloadURL()
  return url
}

export { auth, firestore, storage, uploadImage }
