import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsPlusCircle } from 'react-icons/bs'
import { Container, Img, Label, InputImage, RemoveButton } from './styles'

interface SinglePictureUploadProps {
  image: string
  disabled: boolean
  setImage?: Dispatch<SetStateAction<string>>
}

const SinglePictureUpload: React.FC<SinglePictureUploadProps> = ({
  image,
  disabled,
  setImage
}) => {
  const [imageFile, setImageFile] = useState<File>()

  useEffect(() => {
    if (imageFile && setImage) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = reader.result.toString()
          setImage(base64String)
        }
      }
      reader.readAsDataURL(imageFile)
    }
  }, [imageFile])

  return (
    <Container>
      {!image ? (
        <>
          <Label htmlFor="singleuploadfile">
            {setImage && <BsPlusCircle size={22} color="#B3B3B3" />}
          </Label>
          <InputImage
            id="singleuploadfile"
            type="file"
            accept="image/*"
            disabled={disabled}
            onChange={e => {
              if (e?.target?.files?.length) {
                setImageFile(e.target.files[0])
              }
            }}
          />
        </>
      ) : (
        <>
          {setImage && (
            <RemoveButton
              onClick={() => {
                setImageFile(undefined)
                setImage('')
              }}
            >
              <AiOutlineCloseCircle size={18} color="white" />
            </RemoveButton>
          )}
          <Img src={image} />
        </>
      )}
    </Container>
  )
}

export default SinglePictureUpload
