import styled from 'styled-components'

export const Container = styled.div`
  background: var(--background);
  height: 100%;
  width: 100%;
  display: flex;

  @media (max-width: 1025px) {
    flex-direction: column;
    max-width: 100vw;
  }
  @media (min-width: 900px) {
    max-height: 100vh;
  }
`

export const AlertAction = styled.div.attrs(props => props)`
  width: 100%;
  padding: 16px;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0 0 4px 4px;
  transition: 0.2s ease;
  cursor: pointer;
`

interface AlertBallonProps {
  type: string
  active?: boolean
}

export const AlertBallon = styled.div<AlertBallonProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 4px;
  background: white;
  max-width: 300px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);

  transform: translate(${props => (props.active ? 0 : '330px')}, 0);
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.5s ease;

  @media (max-width: 600px) {
    right: inherit;
    bottom: 15px;
  }

  ${AlertAction} {
    background: ${props => (props.type === 'error' ? '#ee5353' : '#00c851')};

    &:hover {
      background: ${props => (props.type === 'error' ? '#d04343' : '#00a843')};
    }
  }
`

export const AlertTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 16px 16px 5px 16px;
  font-size: 22px;
  color: var(--dark-gray);
`

export const AlertContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 5px 16px 16px 16px;
  font-size: 14px;
  color: var(--medium-gray);
`
