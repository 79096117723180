import React from 'react'

import { Container } from './styles'

export interface ButtonProps {
  size: 'small' | 'large'
  onClick: () => void
  text: string
  inverted?: boolean
  style?: any
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = props => {
  return <Container {...props}>{props.text}</Container>
}

export default Button
