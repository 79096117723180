import React, { useContext, useState, useEffect } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore } from '../../../firebase'

interface ContactsModalProps {
  close: () => void
}

const ContactsModal: React.FC<ContactsModalProps> = ({ close }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [uid, setUid] = useState<string>()
  const [whatsApp, setWhatsApp] = useState<string>('')
  const [facebook, setFacebook] = useState<string>('')
  const [instagram, setInstagram] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const getContacts = async () => {
    const ctgs = await firestore.collection('ContactsCollection').get()

    const data = ctgs.docs.map(doc => {
      const data = doc.data()
      return {
        uid: doc.id,
        whatsApp: data.whatsApp,
        facebook: data.facebook,
        instagram: data.instagram,
        email: data.email
      }
    })

    if (data.length) {
      const { uid, whatsApp, facebook, instagram, email } = data[0]
      setUid(uid)
      setWhatsApp(whatsApp)
      setFacebook(facebook)
      setInstagram(instagram)
      setEmail(email)
    }
  }

  useEffect(() => {
    try {
      getContacts()
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível buscar os contatos!'
      )
    }
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)
    await firestore.collection('ContactsCollection').doc(uid).set(
      {
        whatsApp,
        facebook,
        instagram,
        email
      },
      { merge: true }
    )

    try {
      showAlert('success', 'Sucesso!', 'Contatos atualizados com sucesso!')
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível atualizar os contatos!'
      )
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>Editar Contatos</Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>WhatsApp(apenas números):</InputLabel>
          <InputStyled
            value={whatsApp}
            onChange={e => setWhatsApp(e.target.value)}
          />

          <InputLabel>Facebbok:</InputLabel>
          <InputStyled
            value={facebook}
            onChange={e => setFacebook(e.target.value)}
          />

          <InputLabel>Instagram:</InputLabel>
          <InputStyled
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
          />

          <InputLabel>E-mail:</InputLabel>
          <InputStyled value={email} onChange={e => setEmail(e.target.value)} />
          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar contatos' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default ContactsModal
