import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;

  @media (max-width: 900px) {
    min-width: 100%;
  }
`

export const FilterList = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 0;
  overflow: auto;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    min-width: 100%;
    justify-content: center;
  }
`

export const Blur = styled.div`
  position: absolute;
  width: 30%;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(233, 238, 243);
  background: linear-gradient(
    270deg,
    rgba(233, 238, 243, 1) 0%,
    rgba(233, 238, 243, 0) 100%
  );
`

export const FilterContent = styled.div`
  display: flex;

  @media (max-width: 600px) {
    overflow-x: auto;
  }
`

export const FilterClose = styled.button`
  position: absolute;
  right: 7px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: var(--primary);
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: 0.2s ease;

  -webkit-box-shadow: 0px 0px 25px 25px #bcc1ce;
  -moz-box-shadow: 0px 0px 25px 25px #bcc1ce;
  box-shadow: 0px 0px 25px 25px #bcc1ce;
`

export const FilterItem = styled.div`
  display: flex;
  position: relative;
  border-radius: 100px;
  background-color: var(--secondary);
  height: 28px;
  padding: 0 14px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  overflow: hidden;
  color: var(--black);
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;

  &:hover ${FilterClose} {
    opacity: 1;
  }

  @media (max-width: 600px) {
    overflow: visible;
    margin-bottom: 16px;
  }
`

export const FilterMenu = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

export const VerticalDivider = styled.div`
  display: flex;
  height: 30px;
  width: 1px;
  background-color: var(--medium-gray);

  @media (max-width: 600px) {
    margin-bottom: 16px;
  }
`
