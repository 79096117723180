import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import CategoriasContent from '../../ListagemContent/CategoriasContent'
import Title from '../../Title'
import { Container } from './styles'

const Categorias: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="categorias" />
      <Content>
        <Title text="Categorias" />
        <CategoriasContent />
      </Content>
    </Container>
  )
}

export default Categorias
