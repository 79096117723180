import React, { useContext, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled,
  ImagesUploadContainer
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore, uploadImage } from '../../../firebase'
import SinglePictureUpload from '../../SinglePictureUpload'
import MultiplePictureUpload from '../../MultiplePictureUpload'
import { checkArrayIsSame, firstToUpper } from '../../../lib/helpers'
import firebase from 'firebase'
import { TextAreaStyled } from '../CreateProfissionalModal/styles'
import Dropdown from '../../Dropdowns/Dropdown'

interface IAutomovel {
  uid: string
  model: string
  mileage: number
  owner_name: string
  year: string
  value: number
  created_at: Date
  updated_at: Date
  description: string
  owner_whatsapp?: string
  owner_facebook?: string
  owner_instagram?: string
  owner_email?: string
  default_picture?: string
  active: boolean
  pictures?: string[]
}

interface IAutomovelData {
  model: string
  mileage: number
  owner_name: string
  year: string
  value: number
  description: string
  owner_whatsapp?: string
  active: boolean
  owner_facebook?: string
  owner_instagram?: string
  owner_email?: string
  default_picture?: string | firebase.firestore.FieldValue
  pictures?: string[]
  created_at?: Date
  updated_at?: Date
}

interface CreateAutomovelModalProps {
  close: () => void
  automovelChange?: IAutomovel
  getAutomoveis: () => Promise<void>
}

const CreateAutomovelModal: React.FC<CreateAutomovelModalProps> = ({
  close,
  automovelChange,
  getAutomoveis
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [model, setModel] = useState<string>(
    automovelChange ? automovelChange.model : ''
  )

  const [mileage, setMileage] = useState<number>(
    automovelChange ? automovelChange.mileage : 0
  )

  const [value, setValue] = useState<number>(
    automovelChange ? automovelChange.value : 0
  )

  const [year, setYear] = useState<string>(
    automovelChange ? automovelChange.year : ''
  )

  const [description, setDescription] = useState<string>(
    automovelChange ? automovelChange.description : ''
  )

  const [ownerName, setOwnerName] = useState<string>(
    automovelChange ? automovelChange.owner_name : ''
  )

  const [ownerWhatsApp, setOwnerWhatsApp] = useState<string>(
    automovelChange?.owner_whatsapp || ''
  )

  const [ownerFacebook, setOwnerFacebook] = useState<string>(
    automovelChange?.owner_facebook || ''
  )

  const [ownerInstagram, setOwnerInstagram] = useState<string>(
    automovelChange?.owner_instagram || ''
  )

  const [ownerEmail, setOwnerEmail] = useState<string>(
    automovelChange?.owner_email || ''
  )

  const [defaultPicture, setDefaultPicture] = useState<string>(
    automovelChange?.default_picture || ''
  )

  const [pictures, setPictures] = useState<string[]>(
    automovelChange?.pictures || []
  )

  const [active, setActive] = useState<boolean>(
    automovelChange?.active || false
  )

  const handleSubmit = async () => {
    setIsLoading(true)

    if (!model.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o modelo do automóvel!'
      )
    }

    if (!year.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o ano do automóvel!'
      )
    }

    if (mileage === 0) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, a quilometragem deve ser maior que 0!'
      )
    }

    if (value === 0) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, o valor deve ser maior que 0!'
      )
    }

    if (!ownerName.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o nome do proprietário!'
      )
    }

    const automovelData: IAutomovelData = {
      model: firstToUpper(model),
      year,
      mileage,
      owner_name: ownerName,
      value,
      description,
      active
    }

    if (ownerWhatsApp) automovelData.owner_whatsapp = ownerWhatsApp
    if (ownerFacebook) automovelData.owner_facebook = ownerFacebook
    if (ownerInstagram) automovelData.owner_instagram = ownerInstagram
    if (ownerEmail) automovelData.owner_email = ownerEmail

    if (defaultPicture !== automovelChange?.default_picture) {
      automovelData.default_picture = await uploadImage(
        'Vehicles',
        defaultPicture
      )
    }

    if (!defaultPicture) {
      automovelData.default_picture = firebase.firestore.FieldValue.delete()
    }

    if (!checkArrayIsSame(pictures, automovelChange?.pictures || [])) {
      for (let i = 0; i < pictures.length; i++) {
        if (automovelData.pictures) {
          automovelData.pictures.push(
            await uploadImage('Vehicles', pictures[i])
          )
        } else {
          automovelData.pictures = [await uploadImage('Vehicles', pictures[i])]
        }
      }
    }

    if (!pictures.length) {
      automovelData.pictures = []
    }

    if (automovelChange) {
      automovelData.updated_at = new Date()
    } else {
      automovelData.created_at = new Date()
    }

    await firestore
      .collection('VehiclesCollection')
      .doc(automovelChange?.uid)
      .set(automovelData, { merge: true })

    getAutomoveis()

    try {
      showAlert(
        'success',
        'Sucesso!',
        `Automóvel ${
          automovelChange?.uid ? 'atualizado' : 'criado'
        } com sucesso!`
      )
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        `Não foi possível ${
          automovelChange?.uid ? 'atualizar' : 'criar'
        } o automóvel!`
      )
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>
          {automovelChange ? 'Editar Automóvel' : 'Criar Automóvel'}
        </Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Modelo:</InputLabel>
          <InputStyled value={model} onChange={e => setModel(e.target.value)} />

          <InputLabel>Ano:</InputLabel>
          <InputStyled value={year} onChange={e => setYear(e.target.value)} />

          <InputLabel>Valor:</InputLabel>
          <InputStyled
            value={value}
            type="number"
            min="0.01"
            step="0.01"
            onChange={e => setValue(parseInt(e.target.value))}
          />

          <InputLabel>Quilometragem:</InputLabel>
          <InputStyled
            value={mileage}
            type="number"
            min="1"
            step="1"
            onChange={e => setMileage(parseInt(e.target.value))}
          />

          <InputLabel>Descrição:</InputLabel>
          <TextAreaStyled
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          <InputLabel>Nome do Proprietário:</InputLabel>
          <InputStyled
            value={ownerName}
            onChange={e => setOwnerName(e.target.value)}
          />

          <InputLabel>WhatsApp do Proprietário(apenas números):</InputLabel>
          <InputStyled
            value={ownerWhatsApp}
            onChange={e => setOwnerWhatsApp(e.target.value)}
          />

          <InputLabel>Facebook do Proprietário:</InputLabel>
          <InputStyled
            value={ownerFacebook}
            onChange={e => setOwnerFacebook(e.target.value)}
          />

          <InputLabel>Instagram do Proprietário:</InputLabel>
          <InputStyled
            value={ownerInstagram}
            onChange={e => setOwnerInstagram(e.target.value)}
          />

          <InputLabel>Email do Proprietário:</InputLabel>
          <InputStyled
            value={ownerEmail}
            onChange={e => setOwnerEmail(e.target.value)}
          />

          <Dropdown
            id={6}
            isSearchable={false}
            label="Ativo?"
            disabled={isLoading}
            changeFunction={(v: string) => setActive(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: active },
              { label: 'Não', value: 'false', selected: !active }
            ]}
          />
          <InputLabel>Imagens:</InputLabel>

          <ImagesUploadContainer>
            <SinglePictureUpload
              image={defaultPicture}
              setImage={setDefaultPicture}
              disabled={isLoading}
            />

            <MultiplePictureUpload
              images={pictures}
              setImages={setPictures}
              disabled={isLoading}
            />
          </ImagesUploadContainer>

          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar Automóvel' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default CreateAutomovelModal
