/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol,
  PuffContent,
  ActionButton,
  ShowBanner
} from './styles'

import { MdDelete } from 'react-icons/md'

import Pagination from '../../Pagination'
import { firestore } from '../../../firebase'
import { Modal } from '@material-ui/core'
import BannersModal from '../../Modals/BannersModal'
import ListagemHeader from '../../ListagemHeader'
import firebase from 'firebase'
import { useGeneral } from '../../../contexts/GeneralContext'
import Loading from '../../Loading'

interface IBanners {
  uid: string
  link: string
  default_picture: string
  created_at: Date
  updated_at: Date
}

const BannersContent: React.FC = () => {
  const { showAlert } = useGeneral()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [banners, setBanners] = useState<IBanners[]>([])

  const itemByPage = 5
  const [page, setPage] = useState(1)
  const changePage = (n: number) => setPage(n)

  const [lastDoc, setLastDoc] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >()

  const [totalPages, setTotalPages] = useState(1)

  const getBanners = async () => {
    setLoading(true)

    const totalPages = await firestore
      .collection('BannersCollection')
      .get()
      .then(r => Math.ceil(r.docs.length / itemByPage))

    const ctgs = await firestore
      .collection('BannersCollection')
      .orderBy('created_at')
      .startAfter(page === 1 ? 0 : lastDoc)
      .limit(itemByPage)
      .get()

    const data = ctgs.docs.map(doc => ({
      uid: doc.id,
      ...doc.data()
    }))
    setLastDoc(ctgs.docs[ctgs.docs.length - 1])
    setBanners(data as IBanners[])
    setTotalPages(totalPages)
    setLoading(false)
  }

  useEffect(() => {
    getBanners()
  }, [page])

  const handleDelete = async (uid: string) => {
    setLoading(true)
    await firestore.collection('BannersCollection').doc(uid).delete()
    getBanners()
    try {
      showAlert('success', 'Sucesso!', 'Banner deletado com sucesso!')
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível deletar o banner!'
      )
      setLoading(false)
    }

    close()
  }
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <BannersModal
          close={() => setShowModal(false)}
          getBanners={getBanners}
        />
      </Modal>
      <ListagemHeader
        buttonText="Adicionar Banner"
        fn={() => {
          setShowModal(true)
        }}
      />
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Link</th>
            <th>Imagem</th>
            <th>Action</th>
          </TableDescription>
          <TableContent>
            {loading ? (
              <PuffContent>
                <Loading />
              </PuffContent>
            ) : (
              banners.map((banner, i) => (
                <TableItem key={i}>
                  <ItemCol>
                    <span>{banner.link}</span>
                  </ItemCol>
                  <ItemCol>
                    <ShowBanner src={banner.default_picture} />
                  </ItemCol>
                  <ItemCol>
                    <span>
                      <ActionButton onClick={() => handleDelete(banner.uid)}>
                        <span>
                          <MdDelete size={18} />
                          Excluír
                        </span>
                      </ActionButton>
                    </span>
                  </ItemCol>
                </TableItem>
              ))
            )}
          </TableContent>
        </Table>
      </TableContainer>
      <Pagination
        numberOfItems={totalPages}
        fn={changePage}
        actualPage={page}
      />
    </>
  )
}

export default BannersContent
