import React, { createContext, useState, useEffect, useContext } from 'react'
import { emailRegEx, passwordRegEx } from '../lib/regEx'
import { useGeneral } from './GeneralContext'
import Loading from '../components/Loading'
import { auth, firestore } from '../firebase'

interface IUserData {
  uid: string
  email: string
  name: string
  isAdmin: boolean
  created_at: Date
  updated_at: Date
}

interface ContextProps {
  userData?: IUserData
  authenticated: boolean
  isAuthLoading: boolean
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  recoverPassword: (email: string) => Promise<void>
}

const AuthContext = createContext<ContextProps>({} as ContextProps)

export const AuthProvider: React.FC = ({ children }: any) => {
  const { showAlert } = useGeneral()

  const [currentUser, setCurrentUser] = useState<string>()
  const [userData, setUserData] = useState<IUserData>()
  const [isAuthLoading, setIsAuthLoading] = useState(true)

  const logout = async () => await auth.signOut()

  const getUser = async () => {
    if (!currentUser) {
      throw new Error('Por favor, verifique suas credenciais!')
    }
    try {
      const userD: IUserData | undefined = await firestore
        .collection('UsersCollection')
        .doc(currentUser)
        .get()
        .then(userC => {
          const data = userC.data()
          if (!data) return undefined
          return {
            uid: currentUser,
            email: data.email,
            name: data.name,
            isAdmin: data.isAdmin,
            created_at: data.created_at,
            updated_at: data.updated_at
          }
        })
      if (!userD || !userD.isAdmin) {
        throw new Error('Por favor, verifique suas credenciais!')
      }
      setUserData(userD)
    } catch (e: any) {
      showAlert(
        'error',
        'Ocorreu um erro!',
        e.message || 'Ocorreu um erro, tente novamente mais tarde!'
      )
    }
    setIsAuthLoading(false)
  }

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (!userAuth) {
        setIsAuthLoading(false)
      }
      setCurrentUser(userAuth ? userAuth.uid : undefined)
    })
  }, [])

  useEffect(() => {
    if (currentUser) {
      getUser()
    } else {
      setUserData(undefined)
    }
  }, [currentUser])

  const login = async (email: string, password: string): Promise<void> => {
    if (!emailRegEx.test(email)) {
      return showAlert('error', 'Ocorreu um erro!', 'Insira um e-mail válido!')
    }

    if (!passwordRegEx.test(password)) {
      return showAlert('error', 'Ocorreu um erro!', 'Insira uma senha válida!')
    }

    try {
      const { user } = await auth
        .signInWithEmailAndPassword(email, password)
        .catch(() => ({
          user: null
        }))
      if (!user) {
        throw new Error('Por favor, verifique suas credenciais!')
      }
    } catch (e: any) {
      showAlert(
        'error',
        'Ocorreu um erro!',
        e.message || 'Ocorreu um erro, tente novamente mais tarde!'
      )
    }
  }

  const recoverPassword = async (email: string) => {
    try {
      await auth.sendPasswordResetEmail(email, {
        url: 'https://visance-5c232.firebaseapp.com/',
        handleCodeInApp: true
      })

      showAlert(
        'success',
        'Sucesso!',
        'Enviamos um e-mail para você alterar sua senha!'
      )
    } catch (e: any) {
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Ocorreu um erro, tente novamente mais tarde!'
      )
    }
  }

  if (isAuthLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          paddingTop: 74,
          display: 'flex'
        }}
      >
        <Loading />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        authenticated: !!currentUser && !!userData,
        isAuthLoading,
        login,
        logout,
        recoverPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): ContextProps => useContext(AuthContext)
