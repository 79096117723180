import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: baseline;

  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Text = styled.h1`
  color: var(--black);
`

export const Subtitle = styled.span`
  color: var(--dark-gray);
  font-size: 0.8em;
  margin-left: 20px;

  @media (max-width: 1025px) {
    margin: 0;
  }
`
