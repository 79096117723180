import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 80px;
  height: 80px;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
`

export const RemoveButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 99;
  background-color: red;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
`

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1.5px solid var(--medium-gray);
  cursor: pointer;
`

export const InputImage = styled.input`
  display: none;
`
