import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
`
export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 8px;
`

export const NavigationButton = styled.button`
  color: var(--dark-gray);
  cursor: pointer;
`

interface ItemButtonProps {
  active?: boolean
}

export const ItemButton = styled.button<ItemButtonProps>`
  color: ${props => (props.active ? 'white' : 'var(--primary)')};
  background-color: ${props => (props.active ? 'var(--primary)' : 'white')};
  border-radius: 3px;
  padding: 8px 12px;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: ${props =>
      props.active ? 'var(--primary-hover)' : 'var(--light-gray)'};
  }
`

export const Etc = styled.div`
  padding: 4px;
  font-size: 22px;
  display: flex;
  align-items: flex-end;
  color: var(--primary);
`
