import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import ConfigsContent from '../../ListagemContent/ConfigsContent'
import Title from '../../Title'
import { Container } from './styles'

const Configs: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="configuracoes" />
      <Content>
        <Title text="Configurações" />
        <ConfigsContent />
      </Content>
    </Container>
  )
}

export default Configs
