import React from 'react'
// import { Helmet } from 'react-helmet'
import { AuthProvider } from './contexts/AuthContext'
import { GeneralProvider } from './contexts/GeneralContext'
import ApplicationRoutes from './Routes'
import 'react-datepicker/dist/react-datepicker.css'

import './styles/FontImport.css'
import GlobalStyles from './styles/GlobalStyles'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <GeneralProvider>
        <AuthProvider>
          <ApplicationRoutes />
        </AuthProvider>
      </GeneralProvider>
    </QueryClientProvider>
  )
}

export default App
