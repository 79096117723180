import React from 'react'
import Content from '../../Content'
import LeftMenu from '../../LeftMenu'
import BannersContent from '../../ListagemContent/BannersContent'
import Title from '../../Title'
import { Container } from './styles'

const Banners: React.FC = () => {
  return (
    <Container>
      <LeftMenu active="banners" />
      <Content>
        <Title text="Banners" />
        <BannersContent />
      </Content>
    </Container>
  )
}

export default Banners
