import React, { useContext, useEffect, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled,
  ImagesUploadContainer,
  TextAreaStyled
} from './styles'
import { CgClose } from 'react-icons/cg'
import { auth, firestore, uploadImage } from '../../../firebase'
import SinglePictureUpload from '../../SinglePictureUpload'
import MultiplePictureUpload from '../../MultiplePictureUpload'
import moment from 'moment'
import Dropdown from '../../Dropdowns/Dropdown'
import firebase from 'firebase'
import { checkArrayIsSame, firstToUpper } from '../../../lib/helpers'

interface ISubcategorias {
  uid: string
  name: string
  category: {
    uid: string
    name: string
  }
}

interface Item {
  value: string
  label: string
  selected?: boolean
}

interface Timestamp {
  seconds: number
}

interface IProfissionais {
  uid: string
  name: string
  email: string
  phone: string
  active: boolean
  address: string
  instagram: string
  facebook: string
  isProfessional: boolean
  subcategory?: ISubcategorias
  isHighLight?: boolean
  description?: string
  profileImage?: string
  workImages?: string[]
  created_at: Timestamp
  updated_at?: Timestamp
}

interface INewUser {
  name: string
  phone: string
  isProfessional: boolean
  instagram: string
  facebook: string
  description?: string
  address: string
  email: string
  profileImage?: string | firebase.firestore.FieldValue
  isHighLight?: boolean
  workImages?: string[]
  created_at?: Date
  updated_at?: Date
  active: boolean
  subcategory?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
}

interface CreateProfissionalModalProps {
  close: () => void
  profissionalChange?: IProfissionais
  getProfissionais: () => Promise<void>
}

const CreateProfissionalModal: React.FC<CreateProfissionalModalProps> = ({
  close,
  profissionalChange,
  getProfissionais
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [category, setCategory] = useState<string>(
    profissionalChange?.subcategory?.category.uid || ''
  )

  const [subCategoriesItems, setSubCategoriesItems] = useState<Item[]>([])

  const [subcategory, setSubcategory] = useState<string>(
    profissionalChange?.subcategory?.uid || ''
  )

  const [categoriesItems, setCategoriesItems] = useState<Item[]>([])

  const [name, setName] = useState<string>(
    profissionalChange ? profissionalChange.name : ''
  )

  const [email, setEmail] = useState<string>(
    profissionalChange ? profissionalChange.email : ''
  )

  const [phone, setPhone] = useState<string>(
    profissionalChange ? profissionalChange.phone : ''
  )

  const [active, setActive] = useState<boolean>(
    profissionalChange ? profissionalChange.active : false
  )

  const [isHighLight, setIsHighLight] = useState<boolean>(
    profissionalChange?.isHighLight || false
  )

  const [description, setDescription] = useState<string>(
    profissionalChange?.description || ''
  )

  const [address, setAddress] = useState<string>(
    profissionalChange?.address || ''
  )

  const [facebook, setFacebook] = useState<string>(
    profissionalChange?.facebook || ''
  )

  const [changePassword, setChangePassword] = useState<boolean>(false)

  const [instagram, setInstagram] = useState<string>(
    profissionalChange?.instagram || ''
  )

  const [profileImage, setProfileImage] = useState<string>(
    profissionalChange?.profileImage || ''
  )

  const [workImages, setWorkImages] = useState<string[]>(
    profissionalChange?.workImages || []
  )

  const getCategories = async () => {
    const ctgs = await firestore
      .collection('CategoriesCollection')
      .orderBy('name')
      .get()

    const data = ctgs.docs.map(doc => {
      const data = doc.data()
      return {
        label: data.name,
        value: doc.id,
        selected: doc.id === category
      }
    })

    setCategoriesItems(data)
    setCategory(data.filter(d => d.selected)[0]?.value || data[0]?.value)
  }

  const getSubCategories = async () => {
    const categoryDocRef = firestore
      .collection('CategoriesCollection')
      .doc(category)

    const ctgs = await firestore
      .collection('SubCategoriesCollection')
      .where('category', '==', categoryDocRef)
      .get()

    const data = ctgs.docs.map(doc => {
      const data = doc.data()
      return {
        label: data.name,
        value: doc.id,
        selected: doc.id === subcategory
      }
    })

    setSubCategoriesItems(data)
    setSubcategory(data.filter(d => d.selected)[0]?.value || data[0]?.value)
  }

  useEffect(() => {
    try {
      getCategories()
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível buscar as categorias!'
      )
    }
  }, [])

  useEffect(() => {
    try {
      if (category.length) {
        getSubCategories()
      }
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível buscar as subcategorias!'
      )
    }
  }, [category])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const newUser: INewUser = {
        name: firstToUpper(name),
        email,
        isProfessional: true,
        phone,
        active,
        address,
        facebook,
        instagram
      }
      newUser.description = description
      newUser.isHighLight = isHighLight
      let hasUser = false
      if (profissionalChange?.uid) {
        const checkUsers = await firestore
          .collection('UsersCollection')
          .where('isProfessional', '==', true)
          .where('email', '==', email)
          .where(
            firebase.firestore.FieldPath.documentId(),
            '!=',
            profissionalChange?.uid
          )
          .get()
        if (checkUsers.docs.length > 0) {
          hasUser = true
        }
      }
      if (hasUser) {
        showAlert(
          'error',
          'Ocorreu um erro!',
          'E-mail já está sendo utilizado!'
        )
      } else {
        if (subcategory) {
          const subcategoryDocRef = firestore
            .collection('SubCategoriesCollection')
            .doc(subcategory)
          newUser.subcategory = subcategoryDocRef
        }

        if (profileImage !== profissionalChange?.profileImage) {
          newUser.profileImage = await uploadImage('Profile', profileImage)
        }

        if (!profileImage) {
          newUser.profileImage = firebase.firestore.FieldValue.delete()
        }

        if (
          !checkArrayIsSame(workImages, profissionalChange?.workImages || [])
        ) {
          for (let i = 0; i < workImages.length; i++) {
            if (newUser.workImages) {
              newUser.workImages.push(await uploadImage('Work', workImages[i]))
            } else {
              newUser.workImages = [await uploadImage('Work', workImages[i])]
            }
          }
        }

        if (!workImages.length) {
          newUser.workImages = []
        }

        if (!newUser.profileImage) delete newUser.profileImage

        if (profissionalChange) {
          newUser.updated_at = new Date()
        } else {
          newUser.created_at = new Date()
        }

        await firestore
          .collection('UsersCollection')
          .doc(profissionalChange?.uid)
          .set(newUser, { merge: true })

        if (changePassword) {
          await auth.sendPasswordResetEmail(email)
        }

        getProfissionais()

        showAlert(
          'success',
          'Sucesso!',
          `Profissional ${
            profissionalChange ? 'atualizado' : 'criado'
          } com sucesso!`
        )
      }
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        'Não foi possível ativar o profissional!'
      )
    }
    setIsLoading(false)
    close()
  }

  let created_at = ''
  if (profissionalChange) {
    created_at = moment(profissionalChange?.created_at.seconds * 1000).format(
      'DD/MM/YYYY HH:mm:ss'
    )
  }

  let updated_at = ''
  if (profissionalChange && profissionalChange.updated_at) {
    updated_at = moment(profissionalChange?.updated_at.seconds * 1000).format(
      'DD/MM/YYYY HH:mm:ss'
    )
  }

  return (
    <Container>
      <Header>
        <Title>
          {profissionalChange ? 'Editar Profissional' : 'Criar Profissional'}
        </Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Nome:</InputLabel>
          <InputStyled value={name} onChange={e => setName(e.target.value)} />

          <InputLabel>E-mail:</InputLabel>
          <InputStyled value={email} onChange={e => setEmail(e.target.value)} />
          <Dropdown
            id={8}
            isSearchable={false}
            label="Enviar e-mail para resetar senha?"
            disabled={isLoading}
            changeFunction={(v: string) => setChangePassword(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: changePassword },
              { label: 'Não', value: 'false', selected: !changePassword }
            ]}
          />
          <InputLabel>Telefone:</InputLabel>
          <InputStyled value={phone} onChange={e => setPhone(e.target.value)} />

          <InputLabel>Facebook:</InputLabel>
          <InputStyled
            value={facebook}
            onChange={e => setFacebook(e.target.value)}
          />

          <InputLabel>Instagram:</InputLabel>
          <InputStyled
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
          />

          {profissionalChange && (
            <>
              <InputLabel>Criado em:</InputLabel>
              <InputStyled value={created_at} readOnly={true} />

              <InputLabel>Atualizado em:</InputLabel>
              <InputStyled value={updated_at} readOnly={true} />
            </>
          )}
          <Dropdown
            id={6}
            isSearchable={false}
            label="Ativo?"
            disabled={isLoading}
            changeFunction={(v: string) => setActive(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: active },
              { label: 'Não', value: 'false', selected: !active }
            ]}
          />

          <Dropdown
            id={8}
            isSearchable={false}
            label="É Destaque?"
            disabled={isLoading}
            changeFunction={(v: string) => setIsHighLight(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: isHighLight },
              { label: 'Não', value: 'false', selected: !isHighLight }
            ]}
          />

          <Dropdown
            id={9}
            isSearchable={true}
            label={'Categoria'}
            disabled={!categoriesItems.length}
            changeFunction={(v: string) => setCategory(v)}
            items={categoriesItems}
          />

          <Dropdown
            id={10}
            isSearchable={true}
            label={'Subategoria'}
            disabled={!subCategoriesItems.length}
            changeFunction={(v: string) => setSubcategory(v)}
            items={subCategoriesItems}
          />

          <InputLabel>Descrição:</InputLabel>
          <TextAreaStyled
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          <InputLabel>Endereço:</InputLabel>
          <InputStyled
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          <InputLabel>Imagens:</InputLabel>

          <ImagesUploadContainer>
            <SinglePictureUpload
              image={profileImage}
              setImage={setProfileImage}
              disabled={false}
            />

            <MultiplePictureUpload
              images={workImages}
              setImages={setWorkImages}
              disabled={false}
            />
          </ImagesUploadContainer>
          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar Usuário' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default CreateProfissionalModal
