import React from 'react'
import { Puff } from '@agney/react-loading'

const Loading: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0.5,
        marginTop: -74
      }}
    >
      <div style={{ width: 200, height: 200 }}>
        <Puff />
      </div>
    </div>
  )
}

export default Loading
