import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 15px;
`

interface IsTitleProps {
  isTitle?: boolean
}

export const InputLabel = styled.div<IsTitleProps>`
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-gray);
  height: ${props => (props.isTitle ? 0 : '30px')};
  display: flex;
  align-items: center;
  position: relative;
`

export const InputStyled = styled.input.attrs(props => props)<IsTitleProps>`
  width: 100%;
  height: ${props => (props.isTitle ? '32px' : '40px')};
  padding: ${props => (props.isTitle ? '0' : '0 15px')};
  border-radius: 4px;
  border: ${props =>
    props.isTitle ? 'none' : '1.5px solid var(--medium-gray)'};
  color: ${props => (props.isTitle ? '#3c4043' : 'var(--dark-gray)')};
  font-size: ${props => (props.isTitle ? '28px' : '13px)')};
  font-weight: ${props => (props.isTitle ? 'inherit' : '600px)')};
  margin-bottom: 15px;
  transition: 0.2s ease;
  cursor: pointer;
  line-height: ${props => (props.isTitle ? '28px' : 'inherit')};
  margin: ${props => (props.isTitle ? '20px 0' : '0')};
  display: ${props => (props.isTitle ? 'flex' : 'inherit')};
  align-items: ${props => (props.isTitle ? 'center' : 'inherit')};

  &:focus {
    outline-color: var(--primary);
    outline: ${props => (props.isTitle ? 'none' : 'inherit')};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--medium-gray);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--medium-gray);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--medium-gray);
  }
`

export const ArrowSelect = styled.div<IsTitleProps>`
  display: flex;
  right: 15px;
  top: ${props => (props.isTitle ? '26px' : '40px')};
  position: absolute;
  width: 20px;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
`

export const InputsContainer = styled.div`
  display: flex;
  position: relative;

  ${InputStyled}:nth-child(2) {
    margin-left: 10px;
  }
`

interface DropdownProps {
  active?: boolean
}

export const DropdownContainer = styled.div<DropdownProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% - 15px);
  left: 0;
  right: 0;
  transition: 0.2s ease;
  max-height: 300px;
  overflow-y: auto;

  background-color: white;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);

  z-index: ${props => (props.active ? 100 : 0)};
  transform: ${props => (props.active ? '' : 'translateY(-10%)')};
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
`

export const DropdownItem = styled.div`
  padding: 15px;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--light-gray);
  }
`

export const SearchContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  padding: 15px;

  > svg {
    left: 30px;
    color: var(--medium-gray);
    position: absolute;
  }
`

export const SearchBar = styled.input`
  padding: 10px;
  padding-left: 36px;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: flex;
  flex: 1;
`
