import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: calc(100vh - 65px);
  overflow: hidden;
  max-width: 100vw;

  @media (max-width: 1025px) {
    padding: 20px;
  }
`
