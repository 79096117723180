import React, { useContext, useState } from 'react'
import { GeneralContext } from '../../../contexts/GeneralContext'

import { TailSpin } from '@agney/react-loading'

import {
  Container,
  Header,
  Title,
  CloseIcon,
  Body,
  ColForm,
  InputLabel,
  InputStyled,
  ButtonFilled,
  ImagesUploadContainer
} from './styles'
import { CgClose } from 'react-icons/cg'
import { firestore, uploadImage } from '../../../firebase'
import SinglePictureUpload from '../../SinglePictureUpload'
import MultiplePictureUpload from '../../MultiplePictureUpload'
import { checkArrayIsSame, firstToUpper } from '../../../lib/helpers'
import firebase from 'firebase'
import { TextAreaStyled } from '../CreateProfissionalModal/styles'
import Dropdown from '../../Dropdowns/Dropdown'

interface IImovel {
  uid: string
  district: string
  street: string
  owner_name: string
  active: boolean
  footage: number
  value: number
  created_at: Date
  updated_at: Date
  description: string
  owner_whatsapp?: string
  owner_facebook?: string
  owner_instagram?: string
  owner_email?: string
  default_picture?: string
  pictures?: string[]
}

interface IImovelData {
  district: string
  owner_name: string
  footage: number
  active: boolean
  street: string
  value: number
  owner_whatsapp?: string
  owner_facebook?: string
  owner_instagram?: string
  description: string
  owner_email?: string
  default_picture?: string | firebase.firestore.FieldValue
  pictures?: string[]
  created_at?: Date
  updated_at?: Date
}

interface CreateImovelModalProps {
  close: () => void
  imovelChange?: IImovel
  getImoveis: () => Promise<void>
}

const CreateImovelModal: React.FC<CreateImovelModalProps> = ({
  close,
  imovelChange,
  getImoveis
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { showAlert } = useContext(GeneralContext)

  const [district, setDistrict] = useState<string>(
    imovelChange ? imovelChange.district : ''
  )

  const [footage, setFootage] = useState<number>(
    imovelChange ? imovelChange.footage : 0
  )

  const [value, setValue] = useState<number>(
    imovelChange ? imovelChange.value : 0
  )

  const [street, setStreet] = useState<string>(
    imovelChange ? imovelChange.street : ''
  )

  const [description, setDescription] = useState<string>(
    imovelChange ? imovelChange.description : ''
  )

  const [ownerName, setOwnerName] = useState<string>(
    imovelChange ? imovelChange.owner_name : ''
  )

  const [ownerWhatsApp, setOwnerWhatsApp] = useState<string>(
    imovelChange?.owner_whatsapp || ''
  )

  const [ownerFacebook, setOwnerFacebook] = useState<string>(
    imovelChange?.owner_facebook || ''
  )

  const [ownerInstagram, setOwnerInstagram] = useState<string>(
    imovelChange?.owner_instagram || ''
  )

  const [ownerEmail, setOwnerEmail] = useState<string>(
    imovelChange?.owner_email || ''
  )

  const [defaultPicture, setDefaultPicture] = useState<string>(
    imovelChange?.default_picture || ''
  )

  const [pictures, setPictures] = useState<string[]>(
    imovelChange?.pictures || []
  )

  const [active, setActive] = useState<boolean>(imovelChange?.active || false)

  const handleSubmit = async () => {
    setIsLoading(true)

    if (!district.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o nome do bairro!'
      )
    }

    if (!street.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o nome da rua!'
      )
    }

    if (footage === 0) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, a metragem deve ser maior que 0!'
      )
    }

    if (value === 0) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, o valor deve ser maior que 0!'
      )
    }

    if (!ownerName.trim()) {
      setIsLoading(false)
      return showAlert(
        'error',
        'Ocorreu um erro!',
        'Por favor, insira o nome do proprietário!'
      )
    }

    const imovelData: IImovelData = {
      district: firstToUpper(district),
      footage,
      street,
      owner_name: ownerName,
      value,
      description,
      active
    }

    if (ownerWhatsApp) imovelData.owner_whatsapp = ownerWhatsApp
    if (ownerFacebook) imovelData.owner_facebook = ownerFacebook
    if (ownerInstagram) imovelData.owner_instagram = ownerInstagram
    if (ownerEmail) imovelData.owner_email = ownerEmail

    if (defaultPicture !== imovelChange?.default_picture) {
      imovelData.default_picture = await uploadImage(
        'Properties',
        defaultPicture
      )
    }

    if (!defaultPicture) {
      imovelData.default_picture = firebase.firestore.FieldValue.delete()
    }

    if (!checkArrayIsSame(pictures, imovelChange?.pictures || [])) {
      for (let i = 0; i < pictures.length; i++) {
        if (imovelData.pictures) {
          imovelData.pictures.push(await uploadImage('Properties', pictures[i]))
        } else {
          imovelData.pictures = [await uploadImage('Properties', pictures[i])]
        }
      }
    }

    if (!pictures.length) {
      imovelData.pictures = []
    }

    if (imovelChange) {
      imovelData.updated_at = new Date()
    } else {
      imovelData.created_at = new Date()
    }

    await firestore
      .collection('PropertiesCollection')
      .doc(imovelChange?.uid)
      .set(imovelData, { merge: true })

    getImoveis()

    try {
      showAlert(
        'success',
        'Sucesso!',
        `Imóvel ${imovelChange?.uid ? 'atualizado' : 'criado'} com sucesso!`
      )
    } catch (e) {
      console.log(e)
      showAlert(
        'error',
        'Ocorreu um erro!',
        `Não foi possível ${
          imovelChange?.uid ? 'atualizar' : 'criar'
        } o imóvel!`
      )
    }
    setIsLoading(false)
    close()
  }

  return (
    <Container>
      <Header>
        <Title>{imovelChange ? 'Editar Imóvel' : 'Criar Imóvel'}</Title>
        <CloseIcon onClick={() => close()}>
          <CgClose size={18} />
        </CloseIcon>
      </Header>
      <Body>
        <ColForm>
          <InputLabel>Bairro:</InputLabel>
          <InputStyled
            value={district}
            onChange={e => setDistrict(e.target.value)}
          />

          <InputLabel>Rua:</InputLabel>
          <InputStyled
            value={street}
            onChange={e => setStreet(e.target.value)}
          />

          <InputLabel>Valor:</InputLabel>
          <InputStyled
            value={value}
            type="number"
            min="0.01"
            step="0.01"
            onChange={e => setValue(parseInt(e.target.value))}
          />

          <InputLabel>Metragem:</InputLabel>
          <InputStyled
            value={footage}
            type="number"
            min="1"
            step="1"
            onChange={e => setFootage(parseInt(e.target.value))}
          />

          <InputLabel>Descrição:</InputLabel>
          <TextAreaStyled
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <InputLabel>Nome do Proprietário:</InputLabel>
          <InputStyled
            value={ownerName}
            onChange={e => setOwnerName(e.target.value)}
          />

          <InputLabel>WhatsApp do Proprietário(apenas números):</InputLabel>
          <InputStyled
            value={ownerWhatsApp}
            onChange={e => setOwnerWhatsApp(e.target.value)}
          />

          <InputLabel>Facebook do Proprietário:</InputLabel>
          <InputStyled
            value={ownerFacebook}
            onChange={e => setOwnerFacebook(e.target.value)}
          />

          <InputLabel>Instagram do Proprietário:</InputLabel>
          <InputStyled
            value={ownerInstagram}
            onChange={e => setOwnerInstagram(e.target.value)}
          />

          <InputLabel>Email do Proprietário:</InputLabel>
          <InputStyled
            value={ownerEmail}
            onChange={e => setOwnerEmail(e.target.value)}
          />

          <Dropdown
            id={6}
            isSearchable={false}
            label="Ativo?"
            disabled={isLoading}
            changeFunction={(v: string) => setActive(v === 'true')}
            items={[
              { label: 'Sim', value: 'true', selected: active },
              { label: 'Não', value: 'false', selected: !active }
            ]}
          />

          <InputLabel>Imagens:</InputLabel>

          <ImagesUploadContainer>
            <SinglePictureUpload
              image={defaultPicture}
              setImage={setDefaultPicture}
              disabled={isLoading}
            />

            <MultiplePictureUpload
              images={pictures}
              setImages={setPictures}
              disabled={isLoading}
            />
          </ImagesUploadContainer>

          <ButtonFilled onClick={() => handleSubmit()} disabled={isLoading}>
            {!isLoading ? 'Salvar Imóvel' : <TailSpin />}
          </ButtonFilled>
        </ColForm>
      </Body>
    </Container>
  )
}

export default CreateImovelModal
