import React from 'react'

import FormularioLogin from '../FormularioLogin'
import Banner from '../Banner'

import { Container } from './styles'

const Layout: React.FC = () => {
  return (
    <Container>
      <FormularioLogin />
      <Banner
        title="Bem-vindo à Visance!"
        description="Faça login agora para acessar o painel de controle da Visance."
      />
    </Container>
  )
}

export default Layout
