import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BiBuildingHouse, BiCategory, BiExit } from 'react-icons/bi'
import { CgMenu, CgClose } from 'react-icons/cg'

import {
  Container,
  ContentWrapper,
  ProfileContainer,
  ProfileImage,
  ProfileName,
  ProfileDesc,
  MenuContent,
  MenuItems,
  MenuItem,
  MenuIcon,
  ActiveIndicator,
  HamburguerMenu,
  ScrollContent
} from './styles'
import { useAuth } from '../../contexts/AuthContext'
import { MdOutlineCategory } from 'react-icons/md'
import {
  AiOutlineCar,
  AiOutlinePicture,
  AiOutlineSetting,
  AiOutlineUserSwitch
} from 'react-icons/ai'
import { FiUsers } from 'react-icons/fi'

interface LeftMenuProps {
  active: string
}

const LeftMenu: React.FC<LeftMenuProps> = props => {
  const [menuIsOpened, setMenuIsOpened] = useState(false)

  const { logout, userData } = useAuth()

  function onHandleToggleMenu() {
    setMenuIsOpened(!menuIsOpened)
  }

  const history = useHistory()

  return (
    <Container>
      <ContentWrapper>
        <ProfileContainer>
          <ProfileImage>
            <img
              src="https://i.stack.imgur.com/l60Hf.png"
              alt="Foto de perfil"
            />
          </ProfileImage>
          <ProfileName>{userData?.name}</ProfileName>
          <ProfileDesc>
            <span>Visance</span>
          </ProfileDesc>
        </ProfileContainer>
        <HamburguerMenu isOpened={menuIsOpened} onClick={onHandleToggleMenu}>
          <CgMenu size={26} />
          <CgClose size={26} />
        </HamburguerMenu>
      </ContentWrapper>
      <MenuContent isOpened={menuIsOpened}>
        <ScrollContent>
          <MenuItems>
            <MenuItem
              active={props.active === 'profissionais'}
              onClick={() => history.push('/dashboard')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <AiOutlineUserSwitch size={18} />
              </MenuIcon>
              <span>Profissionais</span>
            </MenuItem>

            <MenuItem
              active={props.active === 'usuarios'}
              onClick={() => history.push('/usuarios')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <FiUsers size={18} />
              </MenuIcon>
              <span>Usuários</span>
            </MenuItem>

            <MenuItem
              active={props.active === 'categorias'}
              onClick={() => history.push('/categorias')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <BiCategory size={18} />
              </MenuIcon>
              <span>Categorias</span>
            </MenuItem>
            <MenuItem
              active={props.active === 'subcategorias'}
              onClick={() => history.push('/subcategorias')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <MdOutlineCategory size={18} />
              </MenuIcon>
              <span>Subcategorias</span>
            </MenuItem>
            <MenuItem
              active={props.active === 'imoveis'}
              onClick={() => history.push('/imoveis')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <BiBuildingHouse size={18} />
              </MenuIcon>
              <span>Imóveis</span>
            </MenuItem>
            <MenuItem
              active={props.active === 'automoveis'}
              onClick={() => history.push('/automoveis')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <AiOutlineCar size={18} />
              </MenuIcon>
              <span>Automóveis</span>
            </MenuItem>
            <MenuItem
              active={props.active === 'banners'}
              onClick={() => history.push('/banners')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <AiOutlinePicture size={18} />
              </MenuIcon>
              <span>Banners</span>
            </MenuItem>
            <MenuItem
              active={props.active === 'configuracoes'}
              onClick={() => history.push('/configuracoes')}
            >
              <ActiveIndicator />
              <MenuIcon>
                <AiOutlineSetting size={18} />
              </MenuIcon>
              <span>Configurações</span>
            </MenuItem>
          </MenuItems>
        </ScrollContent>
        <MenuItem isExit onClick={logout}>
          <MenuIcon>
            <BiExit size={18} />
          </MenuIcon>
          <span>Sair</span>
        </MenuItem>
      </MenuContent>
    </Container>
  )
}

export default LeftMenu
