/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react'

import {
  TableContainer,
  Table,
  TableDescription,
  TableContent,
  TableItem,
  ItemCol,
  PuffContent
} from './styles'

import Pagination from '../../Pagination'
import { firestore } from '../../../firebase'
import { Modal } from '@material-ui/core'
import CreateCategoryModal from '../../Modals/CreateCategoryModal'
import ListagemHeader from '../../ListagemHeader'
import firebase from 'firebase'
import Loading from '../../Loading'

interface ICategorias {
  uid: string
  name: string
  default_picture?: string
  created_at: Date
  updated_at: Date
}

const CategoriasContent: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [categorias, setCategories] = useState<ICategorias[]>([])

  const itemByPage = 10
  const [page, setPage] = useState(1)
  const changePage = (n: number) => setPage(n)

  const [lastDoc, setLastDoc] =
    useState<
      firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    >()

  const [totalPages, setTotalPages] = useState(1)

  const getCategories = async () => {
    setLoading(true)

    const totalPages = await firestore
      .collection('CategoriesCollection')
      .get()
      .then(r => Math.ceil(r.docs.length / itemByPage))

    const ctgs = await firestore
      .collection('CategoriesCollection')
      .orderBy('name')
      .startAfter(page === 1 ? 0 : lastDoc)
      .limit(itemByPage)
      .get()

    const data = ctgs.docs.map(doc => ({
      uid: doc.id,
      ...doc.data()
    }))
    setLastDoc(ctgs.docs[ctgs.docs.length - 1])
    setCategories(data as ICategorias[])
    setTotalPages(totalPages)
    setLoading(false)
  }

  useEffect(() => {
    getCategories()
  }, [page])

  const [categoryChange, setCategoryChange] = useState<ICategorias>()

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CreateCategoryModal
          close={() => setShowModal(false)}
          categoryChange={categoryChange}
          getCategories={getCategories}
        />
      </Modal>
      <ListagemHeader
        buttonText="Adicionar Categoria"
        fn={() => {
          setShowModal(true)
          setCategoryChange(undefined)
        }}
      />
      <TableContainer>
        <Table>
          <TableDescription>
            <th>Nome</th>
          </TableDescription>
          <TableContent>
            {loading ? (
              <PuffContent>
                <Loading />
              </PuffContent>
            ) : (
              categorias.map((categoria, i) => (
                <TableItem
                  key={i}
                  onClick={() => {
                    setShowModal(true)
                    setCategoryChange(categoria)
                  }}
                >
                  <ItemCol>
                    <span>{categoria.name}</span>
                  </ItemCol>
                </TableItem>
              ))
            )}
          </TableContent>
        </Table>
      </TableContainer>
      <Pagination
        numberOfItems={totalPages}
        fn={changePage}
        actualPage={page}
      />
    </>
  )
}

export default CategoriasContent
