import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    color: var(--white);
  }
  html, border-style, #root{
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
  }
  body {
    overflow-x: hidden;
  }
  *, button, input{
    border: 0;
    background: none;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 18px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    width: 20px;
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 5px solid white;
    background: var(--medium-gray);
    border-radius: 18px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--dark-gray);
  }
  
  :root{
    --primary: #003333;
    --secondary: #015857;
    --primary-hover: #1A1A1A;
    --light-gray: #F1F1F1;
    --medium-gray: #B3B3B3;
    --dark-gray: #A3A3A3;
    --black: #333333;
    --black-hover: #171717;
    --shadow: #8A959E;
    --red-indicator: #FF4536;
    --green-indicator: #32D74B;
    --blue-indicator: #53b1b1;
    --background: #E9EEF4;
    --highlight: #FFB325;
  }
`
