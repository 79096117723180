import styled from 'styled-components'

export const Container = styled.div`
  display: block;
  width: 65%;
  height: 80px;
`
export const Scroll = styled.div`
  overflow-y: auto;
  display: flex;
  height: 125%;
`

export const ImgContainer = styled.div`
  position: relative;
`

export const Img = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`

export const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: -2px;
  z-index: 99;
  background-color: red;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
`

export const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1.5px solid var(--medium-gray);
  cursor: pointer;
`

export const InputImage = styled.input`
  display: none;
`
