import styled from 'styled-components'

export const Cta = styled.button.attrs(props => props)`
  display: flex;
  padding: 0 30px;
  height: 30px;
  background: var(--primary);
  transition: 0.2s ease;
  color: white;
  font-weight: bold;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);

  &:hover {
    background: var(--primary-hover);
  }
  &:focus,
  &:active {
    outline: none;
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 100vw;
  overflow: auto;
  margin-top: 24px;

  @media (max-width: 960px) {
    padding: 0 0 15px 0;
  }
`

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > tr > th,
  & > div > tr > td {
    :nth-child(1) {
      padding-left: 10px;
      white-space: nowrap;
      width: 100%;
    }
  }
  @media (max-width: 960px) {
    padding-right: 0;
  }
  @media (max-width: 600px) {
    min-height: 500px;
  }
`

export const TableDescription = styled.tr`
  display: flex;
  margin-bottom: 10px;

  & > th {
    font-size: 13px;
    font-weight: 600;
    color: var(--dark-gray);
    display: flex;
    justify-content: flex-start;
  }
`

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  background: white;
  overflow: auto;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(138, 149, 158, 0.15);
`

export const ActiveIndicator = styled.div`
  opacity: 0;
  height: 100%;
  width: 4px;
  background-color: var(--primary);
  transition: 0.2s ease;
`

export const TableItem = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid #f1f1f1;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #f7f8fc;

    ${ActiveIndicator} {
      opacity: 1;
    }
  }
`

export const ItemCol = styled.td`
  display: flex;
  height: 100%;
  align-items: center;
  color: var(--dark-gray);
  font-size: 12.5px;
  font-weight: 600;

  :nth-child(2) {
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 13px;
  }

  > span {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const ActionButton = styled.button.attrs(props => props)`
  display: flex;
  align-items: center;
  color: var(--dark-gray);
  margin-right: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    color: var(--primary);
  }
  &:active,
  &:focus {
    outline: none;
  }
  & > span {
    display: flex;
    align-items: center;
    min-width: 37px;
    & > svg {
      margin-right: 5px;
    }
  }
`

export const PuffContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  & > svg {
    max-height: 250px;
  }
`
