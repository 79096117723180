import React from 'react'

import { Switch, BrowserRouter as Router } from 'react-router-dom'

import AdminRoute from './AdminRoute'
import AuthRoute from './AuthRoute'

import Home from './components/Pages/Home'
import Login from './components/LayoutAuth'
import Categorias from './components/Pages/Categorias'
import Subcategorias from './components/Pages/Subcategorias'
import Imoveis from './components/Pages/Imoveis'
import Automoveis from './components/Pages/Automoveis'
import Users from './components/Pages/Users'
import Banners from './components/Pages/Banners'
import Configs from './components/Pages/Configs'

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <AdminRoute exact path="/dashboard" redirectTo="/" component={Home} />

        <AdminRoute exact path="/usuarios" redirectTo="/" component={Users} />

        <AdminRoute
          exact
          path="/categorias"
          redirectTo="/"
          component={Categorias}
        />

        <AdminRoute
          exact
          path="/subcategorias"
          redirectTo="/"
          component={Subcategorias}
        />

        <AdminRoute exact path="/imoveis" redirectTo="/" component={Imoveis} />

        <AdminRoute
          exact
          path="/automoveis"
          redirectTo="/"
          component={Automoveis}
        />

        <AdminRoute exact path="/banners" redirectTo="/" component={Banners} />

        <AdminRoute
          exact
          path="/configuracoes"
          redirectTo="/"
          component={Configs}
        />

        <AuthRoute path="/" component={Login} />
      </Switch>
    </Router>
  )
}

export default Routes
