import styled from 'styled-components'
import { ButtonProps } from '.'

export const Container = styled.button<ButtonProps>`
  height: 30px;
  border-radius: 3px;
  padding: 0 20px;
  cursor: pointer;
  font-weight: 600;
  background-color: ${props => (props.inverted ? 'white' : 'var(--primary)')};
  color: ${props => (!props.inverted ? 'white' : 'var(--primary)')};
  transition: 0.2s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${props =>
      props.inverted ? 'var(--light-gray)' : 'var(--primary-hover)'};
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }
`
